import React, {useState, useRef, useEffect} from 'react';
import {
  CircularProgress,
  Card,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  IconButton,
  Avatar,
  Checkbox,
  Switch,
  FormControlLabel,
  Badge,
  Button,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import appPaths from '../../applicationPaths.json';
import { useAuth0 } from "../../react-auth0-wrapper";
import RoomIcon from '@material-ui/icons/Room';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import theme from '../../themes/theme';
import ImageModal from '../Map/Image';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DoneIcon from '@material-ui/icons/Done';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InvoiceMaterialDropdown from "./InvoiceMaterialDropdown";
import InvoiceMaterialUnitsDropdown from "./InvoiceMaterialUnitDropdown";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%'

  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function InvoiceMaterials(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [materiallist, setMaterialList] = useState([]);
    const { isAuthenticated, loginWithRedirect, logout, user, getTokenSilently } = useAuth0();
    const [permissionType, setPermissionType] = useState([]);

    useEffect(() => {
      const getPermissions = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/getUserPermissions", {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
    
          const responseData = await response.json();
          setPermissionType(responseData);
        }
        catch (error) {
          console.error(error);
        }
      };
      getPermissions();
    }, [getTokenSilently, user])

    const changeChoice = (choice, item) => {
        props.setMaterialList(prevState => {
          return [...prevState.map(material => {
            if(material !== item)
            {
              return material;
            }
            else
            {
              material.price = choice.fieldData.UnitPrice_n;
              material.units = choice.fieldData.Unit;
              return material;
            }
          })];
        });
    };




    useEffect(() => {
      const getMaterials = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/getMaterials", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({id: props.jobId})
          });
    
          const responseData = await response.json();
          if(responseData.message)
          {
            setMaterialList(responseData.data.response.data)
            setLoading(false);
            
            //setJobDetails(responseData.data.response.data[0].fieldData);
            //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
          }
          else
          {
              setLoading(false);
            //alert(responseData);
          }
  
        } catch (error) {
          console.error(error);
        }
      };
        getMaterials();
    }, [getTokenSilently, props.jobId]);


  return  (
  <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', flexDirection:'column', borderRadius: '0px'}}>
      {!loading && 
          <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px'}}>
              <Button onClick={() => {
                  props.setMaterialList(prevState => {return [...prevState, {name:"", price:"", qty: "", units: ""}]})
              }} variant="contained" color="primary">Add New Material</Button>
              <List>
                
                {props.materialList.map((item, i) => {
                  return (
                    <div key={i} >
                    <ListItem style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px'}} key={i}>
                      <Grid container spacing={2}>
                        <Grid item xs={10} sm={11}>
                          <InvoiceMaterialDropdown changeValue={(value) => {
                            props.setMaterialList(prevState => {
                              return [...prevState.map(material => {
                                if(material !== item)
                                {
                                  return material;
                                }
                                else
                                {
                                  material.name = value;
                                  return material;
                                }
                              })];
                            }
                          )
                          }} value={item.name} changeChoice={(choice) => { changeChoice(choice, item)}} materials={materiallist} />
                        </Grid>
                        <Grid item xs={2} sm={1}>
                        <IconButton onClick={(item) => { props.setMaterialList(prevState => {
                          return prevState.filter((val, k) => { return k !== i;})
                      })}}>
                        <DeleteForeverIcon />
                      </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        {/*
                        <Grid item xs={4}>
                      <TextField
                          style={{width: '100%'}}
                          label="Price"
                          onChange={(event) =>
                            {
                              event.persist();
                            props.setMaterialList(prevState => {
                              return [...prevState.map(material => {
                                if(material !== item)
                                {
                                  return material;
                                }
                                else
                                {
                                  material.price = event.target.value;
                                  return material;
                                }
                              })];
                            }
                          )}}
                          value={item.price}
                        >

                        </TextField>
                      </Grid>
                          */}
                      <Grid item xs={permissionType.includes("type:admin") ? 4 : 6}>
                        {/*<TextField
                          style={{width: '100%'}}
                          label="Unit"
                          onChange={(event) =>
                            {
                              event.persist();
                            props.setMaterialList(prevState => {
                              return [...prevState.map(material => {
                                if(material !== item)
                                {
                                  return material;
                                }
                                else
                                {
                                  material.units = event.target.value;
                                  return material;
                                }
                              })];
                            }
                          )}}
                          value={item.units}
                        >

                        </TextField>*/}

                          <InvoiceMaterialUnitsDropdown
                            changeValue={(value) => {
                              props.setMaterialList(prevState => {
                                return [...prevState.map(material => {
                                  if(material !== item)
                                  {
                                    return material;
                                  }
                                  else
                                  {
                                    material.units = value;
                                    return material;
                                  }
                                })];
                              })
                            }}
                            value={item.units}
                            changeChoice={() => {}}
                            materials={[...new Set(materiallist.map(material => { return material.fieldData.Unit }))]}
                            label="Unit of Measurement"
                            placeholder="Unit of Measurement"
                          />

                        {/*
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel htmlFor="outlined-age-simple">
                            Unit
                          </InputLabel>
                          <Select
                            value={item.units}
                            onChange={(event) =>
                            {
                                event.persist();
                              props.setMaterialList(prevState => {
                                return [...prevState.map(material => {
                                  if(material !== item)
                                  {
                                    return material;
                                  }
                                  else
                                  {
                                    material.units = event.target.value;
                                    return material;
                                  }
                                })];
                              });
                            }}
                            inputProps={{
                              name: 'age',
                              id: 'outlined-age-simple',
                            }}
                          >
                            
                            {[...new Set(materiallist.map(material => { return material.fieldData.Unit }))].map((material) => <MenuItem key={material} value={material}>
                              {material}
                            </MenuItem>)}
                          </Select>
                        </FormControl>*/}
                      </Grid>
                      {permissionType.includes("type:admin") &&
                      <Grid item xs={4}>
                        <TextField
                          style={{width: '100%'}}
                          label="Price"
                          onChange={(event) =>
                            {
                              event.persist();
                            props.setMaterialList(prevState => {
                              return [...prevState.map(material => {
                                if(material !== item)
                                {
                                  return material;
                                }
                                else
                                {
                                  material.price = event.target.value;
                                  return material;
                                }
                              })];
                            }
                          )}}
                          value={item.price}
                        >

                        </TextField>

                      </Grid>
                      }
                      <Grid item xs={permissionType.includes("type:admin") ? 4 : 6}>
                      <TextField
                        style={{width: '100%'}}
                        label="Quantity"
                        onChange={(event) =>
                          {
                            event.persist();
                          props.setMaterialList(prevState => {
                            return [...prevState.map(material => {
                              if(material !== item)
                              {
                                return material;
                              }
                              else
                              {
                                material.qty = event.target.value;
                                return material;
                              }
                            })];
                          }
                        )}}
                        value={item.qty}
                      >

                      </TextField>

                    </Grid>
                    </Grid>
                    </ListItem>
                    <Divider />
                        
                    </div>
                    );
                })}
                {permissionType.includes("type:admin") &&
                <ListItem style={{textAlign:'right', height: '200px'}}><ListItemText primary={"Material Total: $" + props.materialList.reduce((a,b) => {
                  return (parseFloat(a) + ((b === 0 ? 0 : parseFloat(b.price === "" ? 0 : b.price.toString().replace("$", ""))) * (b === 0 ? 0 : parseFloat(b.qty === "" ? 0 : b.qty.toString().replace("$", "")))))
                  }, 0 ).toFixed(2)}
                />
                </ListItem>
                }
                {!permissionType.includes("type:admin") &&
                <ListItem style={{height: '200px'}} />
                }
            </List>
          </Card>
      }
      {loading && <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', borderRadius: '0px'}}><CircularProgress></CircularProgress></div>}
  </div>
  );
};