import React, {useState, useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import InvoiceMarkers from './InvoiceMarkers';
import InvoiceImages from './InvoiceImages';
import InvoiceOverview from './InvoiceOverview';
import InvoiceMaterials from './InvoiceMaterials';
import {Stepper, Step, StepLabel, Typography, Paper, CircularProgress} from '@material-ui/core';
import { useAuth0 } from "../../../react-auth0-wrapper";
import appPaths from '../../../applicationPaths.json';
import { Redirect } from 'react-router';

const useStyles = makeStyles(theme => ({
  stepper: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

function getSteps() {
  return ['Markers', 'Images', 'Materials', 'Review'];
}

export default function Invoice(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [selectedImages, setSelectedImages] = useState([]);
  const [images, setImages] = useState([]);
  const [imageMapData, setImageMapData] = useState([]);
  const [mapRef, setMapRef] = useState(null);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(16);
  const { isAuthenticated, loginWithRedirect, logout, getTokenSilently, user } = useAuth0();
  const [invoiceLoading, setInvoiceLoading] = useState(-1);
  const [materialList, setMaterialList] = useState([]);
  const [redirectDetails, setRedirectDetails] = useState({set: false, location: "/job"});
  const [features, setFeatures] = useState([]);
  const [allFeatures, setAllFeatures] = useState([]);
  const [permissionType, setPermissionType] = useState([]);
  const [invoiceContentLoading, setInvoiceContentLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [oldMarkers, setOldMarkers] = useState([]);
  const [oldPictures, setOldPictures] = useState([]);
  const [oldMaterials, setOldMaterials] = useState([]);
  const [invoiceId, setInvoiceId] = useState([]);

  useEffect(() => {
    const callApi = async () => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(appPaths.apiPath + "/api/getInvoiceData", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
          body: JSON.stringify({id: props.location.state.invoiceId})
        });
        const responseData = await response.json();

        console.log(responseData);
        if(responseData.message)
        {
          setInvoiceContentLoading(false);
          setDescription(responseData.data.invoice.fieldData.Description);
          let markers = responseData.data.invoice.fieldData.invoice_features;
          setOldMarkers(markers);

          let pictures = responseData.data.invoiceItems.filter( item => item.fieldData._idf_picture !== '' ).map( image => image.fieldData._idf_picture );
          setOldPictures(pictures);

          let materials = responseData.data.invoiceItems.filter( item => item.fieldData.pictureSectionMaterial_flag !== '' );
          setOldMaterials(materials);

          setInvoiceId(responseData.data.invoice.fieldData.__ID);
          //setJobDetails(responseData.data.response.data[0].fieldData);
          //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
        }
        else
        {
          setInvoiceContentLoading(false);
          //alert(responseData);
        }
      } catch (error) {
        console.error(error);
      }
    };
    callApi();
  }, [getTokenSilently, props]);

  useEffect(() => {
    console.log(allFeatures);
    if(oldMarkers.length > 0 && allFeatures.length > 0)
    {
      setFeatures(allFeatures.filter(feature => oldMarkers.includes(feature.getProperty('id'))));
    }
  }, [oldMarkers, allFeatures]);


  useEffect(() => {
    const getPermissions = async () => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(appPaths.apiPath + "/api/getUserPermissions", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        const responseData = await response.json();
        setPermissionType(responseData);
      }
      catch (error) {
        console.error(error);
      }
    };
    getPermissions();
  }, [getTokenSilently, user])



  
    function handleNext() {
      /*if(activeStep == 0)
      {
        html2canvas(document.getElementById('googleMarkerMap'), {
          useCORS: true,
      allowTaint: false,
      ignoreElements: (node) => {
        return node.nodeName === 'IFRAME';
      }
        }).then(function(canvas) {
          //console.log(canvas);
          document.getElementById('mapImage').appendChild(canvas);
      });
      }
*/
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  
    function handleBack() {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function addImages(index) {
      setSelectedImages(prevState => { return [...images.filter(image => image.fieldData._idf_marker == index), ...prevState];})
    }
    function removeImages(index) {
      setSelectedImages(prevState => { return [...prevState.filter(image => image.fieldData._idf_marker != index)];})
    }

    const createInvoice = (mapUrl) => {
      setInvoiceLoading(0);
      var bodyData = {};
      bodyData.mapUrl = mapUrl;
      bodyData.description = description;
      bodyData.selectedImages = selectedImages;
      bodyData._idf_job = props.location.state.__ID;
      bodyData.materialList = materialList;
      bodyData.features = features.map( feature => feature.getProperty('id') );
      bodyData.__ID = invoiceId;

      createNewInvoice(bodyData);
    };
    const createNewInvoice = async (body) => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(appPaths.apiPath + "/api/editInvoice", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
          body: JSON.stringify(body)
        });
  
        const responseData = await response.json();
        //console.log(responseData);
        if(responseData.message)
        {
          if(permissionType.includes("type:admin"))
          {
            window.open(responseData.invoice.response.data[0].fieldData.Pdf_o);
          }
          setInvoiceLoading(1);
          let data = {set: true, to: {pathname: "/job", state: {__ID: props.location.state.__ID}}};
          setRedirectDetails(data);
          
        }
        else
        {
          setInvoiceLoading(2);
          //alert(responseData);
        }

      } catch (error) {
        console.error(error);
      }
    };

  
    return (
        <div style={{width: '100%'}}>
          {!invoiceContentLoading &&
          <>
          {invoiceLoading === -1 &&
          <>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            className={classes.root}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === 3}>
                Next
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </Button>
            }
          />
        <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
        {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
        ))}
        </Stepper>
        <Paper className={classes.sectionMobile} style={{borderRadius: '0px'}}>
        <Typography variant="h5" style={{width:'100%', textAlign: 'center'}}>
          {getSteps()[activeStep]}
        </Typography>
        </Paper>
        
      
      <div style={{display: (activeStep == 0 ? "flex" : "none")}}>
        <InvoiceMarkers allFeatures={allFeatures} setAllFeatures={setAllFeatures} features={features} setFeatures={setFeatures} setZoom={setZoom} setCenter={setCenter} setMapRef={setMapRef} setImageMapData={setImageMapData} addImages={addImages} removeImages={removeImages} selectedImages={selectedImages} jobId={props.location.state.__ID} />
      </div>
      <div style={{display: (activeStep == 1 ? "flex" : "none")}}>
        <InvoiceImages imageMapData={imageMapData} images={images} changeImages={setImages} changeSelectedImages={(val) => setSelectedImages(val)} selectedImages={selectedImages} jobId={props.location.state.__ID} oldPictures={oldPictures} />
      </div>
      <div style={{display: (activeStep == 2 ? "flex" : "none")}}>
        <InvoiceMaterials materialList={materialList} setMaterialList={setMaterialList} zoom={zoom} tab={activeStep} center={center} mapData={imageMapData} selectedImages={selectedImages} mapRef={mapRef} jobId={props.location.state.__ID} oldMaterials={oldMaterials} />
      </div>
      <div style={{display: (activeStep == 3 ? "flex" : "none")}}>
        <InvoiceOverview description={description} url={url} setDescription={setDescription} setUrl={setUrl} features={features} createInvoice={createInvoice} zoom={zoom} tab={activeStep} center={center} mapData={imageMapData} selectedImages={selectedImages} mapRef={mapRef} jobId={props.location.state.__ID} />
      </div>
      
      </>}
      {invoiceLoading === 0 && <CircularProgress></CircularProgress>}
      {invoiceLoading === 1 && redirectDetails.set && <Redirect push to={redirectDetails.to} />}
      {invoiceLoading === 2 && <div>Invoice Creation Failed. Please Try again</div>}
      </>
      }
      
      </div>
    );
}