import React, { Component, useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-wrapper";
import { createMuiTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import theme from '../../themes/theme';
import appPaths from '../../applicationPaths.json';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ListItem, Divider } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


export default function InvoiceList(props) {
    const [contentLoading, setContentLoading] = useState(true);
    const [invoiceList, setInvoiceList] = useState([]);
    const { isAuthenticated, loginWithRedirect, logout, getTokenSilently, user, loading } = useAuth0();

    const openPdf = (e, invoice) => {
        e.stopPropagation();
        window.open(invoice);

    }

    useEffect(() => {
        const callApi = async () => {
            try {
              const token = await getTokenSilently();
              const response = await fetch(appPaths.apiPath + "/api/getJobInvoices", {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',  // sent request
                  'Accept':       'application/json'   // expected data sent back
                },
                body: JSON.stringify({jobId: props.__ID})
              });
              const responseData = await response.json();
              console.log(responseData);
              if(responseData.message)
              {
                /*responseData.data.forEach(tech => {
                    fetch(appPaths.apiPath + "/api/getUserByFmId", {
                        method: 'POST',
                        headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',  // sent request
                        'Accept':       'application/json'   // expected data sent back
                        },
                        body: JSON.stringify({id: tech.fieldData.__ID})
                    })
                    .then(res => { return res.json() })
                    .then(res => { tech.auth0 = res.data; setTechList(prevState => [...prevState.filter(tech1 => tech1.recordId !== tech.recordId), tech]); })
                })*/
                setInvoiceList(responseData.data);

                setContentLoading(false);
              }
              else
              {
                setInvoiceList([]);

                setContentLoading(false);
                //alert(responseData);
              }
    
            } catch (error) {
              console.error(error);
            }
          };
            callApi();
    }, [getTokenSilently, props.__ID]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width:'100%'}}>
            {!contentLoading &&
            <>
                <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column'}}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Open Invoices</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <List style={{width:'100%'}}>
                                <Divider />
                                {invoiceList.map(invoice => (
                                    <div key={invoice.fieldData.__ID}>
                                        <ListItem button onClick={() => {props.setRedirect({set: true, to: {pathname: "/job-edit-invoice", state: {__ID: props.__ID, invoiceId: invoice.fieldData.__ID}}})}}>
                                            <ListItemAvatar>
                                                <IconButton>
                                                <Avatar  style={{backgroundColor:theme.palette.secondary.main}} alt={invoice.fieldData.Number_INV} src={invoice.fieldData.pictureOverheadMap_o != "" ? appPaths.apiPath + "/api/getFMInvoiceImage?url=" + invoice.fieldData.__ID : ""} />
                                                </IconButton>
                                            </ListItemAvatar>
                                            <ListItemText primary={invoice.fieldData.Number_INV} />
                                            {invoice.fieldData.Pdf_o && <ListItemIcon>
                                                <IconButton onClick={(e) => openPdf(e, invoice.fieldData.Pdf_o)}>
                                                    <PictureAsPdfIcon />
                                                </IconButton>
                                            </ListItemIcon>}
                                            <ListItemIcon>
                                                <IconButton onClick={() => {props.setRedirect({set: true, to: {pathname: "/job-edit-invoice", state: {__ID: props.__ID, invoiceId: invoice.fieldData.__ID}}})}}>
                                                    <EditIcon />
                                                </IconButton>
                                            </ListItemIcon>

                                        </ListItem>
                                        <Divider />
                                    </div>
                                ))}
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    
                </Card>
            </>
            }
            {contentLoading && <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', borderRadius: '0px'}}><CircularProgress></CircularProgress></div>}

        </div>
    );

}