import React, { useState, useEffect, forwardRef } from 'react';
import styles from './home.module.css';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { useAuth0 } from "../react-auth0-wrapper";
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import appPaths from '../applicationPaths.json';
import { Redirect } from 'react-router';
import Zoom from '@material-ui/core/Zoom';
import Example from './ReactTable';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      margin: '20px',
      marginTop: '0px'
    },
    table: {
    },
  }));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const JobsList = () => {

    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const [showResult, setShowResult] = useState(false);
    const [jobsList, setJobsList] = useState([]);
    const [toAlertDetails, setToAlertDetails] = useState({set: false, location: "/job"});
    const [updateAlerts, setUpdateAlerts] = useState(true);
    const [updated, setUpdated] = useState(false);
    const { getTokenSilently } = useAuth0();

    function handleChange(event, newValue) {
        setValue(newValue);
      }
    
      function handleChangeIndex(index) {
        setValue(index);
      }

      const handleClick = (id) => {
        let data = {set: true, to: {pathname: "/job", state: {__ID: id.__ID}}};
        setToAlertDetails(data);
      }


      useEffect(() => {
        if(!setShowResult)
        {
          if(localStorage.getItem('jobsList'))
          {
            setShowResult(true);
            setJobsList(JSON.parse(localStorage.getItem('jobsList')));
          }
        }
        
      }, [jobsList, setShowResult]);
    
      useEffect(() => {
    
        if(updateAlerts && isAuthenticated)
        {
          setUpdateAlerts(false);
          const callApi = async () => {
            try {
              const token = await getTokenSilently();
              const response = await fetch(appPaths.apiPath + "/api/getJobsList", {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
        
              const responseData = await response.json();
              if(responseData.message)
              {
                console.log(responseData);
                if(responseData.data !== null)
                {

                  
                  setShowResult(true);
                  setUpdateAlerts(false);
                  setUpdated(true);
                  if(responseData.data.response.data.length > 0)
                  {
                    localStorage.setItem('jobsList', JSON.stringify(responseData.data.response.data));
                    setJobsList(responseData.data.response.data);
                  }
                  else
                  {
                    localStorage.setItem('jobsList', '');
                    setJobsList([]);
                  }
                }
                else
                {
                  setShowResult(true);
                  setUpdateAlerts(false);
                  setUpdated(true);
                  localStorage.setItem('jobsList', '');
                  setJobsList([]);
                }
              }
              else
              {
                setShowResult(true);
                setUpdateAlerts(false);
                console.log(responseData);
              }
    
            } catch (error) {
              setUpdateAlerts(true);
              console.error(error);
              setShowResult(false);
            }
          };
            callApi();
        }
      
    }, [getTokenSilently, isAuthenticated, updateAlerts]);


    const columns = [
      { title: 'Customer Name', field: 'Customer_lu' },
      { title: 'Job/Site Name', field: 'Job_Name' },
      { title: 'Job #', field: 'Number_JobCombined' },

      /*{
        field: 'JobType',
        Title: 'Type',
        render: rowData => <Edit />,
        filtering: false
      },
      //{ title: 'Job Type', field: 'JobType' }*/
    ];

    return (<>
    
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        
            {toAlertDetails.set && 
        (<Redirect push to={toAlertDetails.to} />
      )}
                    {isAuthenticated && (
            <div className={styles.Body}>
              
            {/*<table className={styles.alertTable}>
              <thead><tr><th>Submitted At</th><th>Company Name</th></tr></thead>
              <tbody>
            {showResult && apiMessage.map(function(d){
         return (<tr key={d.fieldData.__ID}><td key={"creation" + d.fieldData.__ID}>{d.fieldData.z_Creation_TS}</td><td key={"company" + d.fieldData.__ID}>{d.fieldData.CompanyName}</td></tr>)
       })
      }
      </tbody>
            </table>*/}
            
            <Zoom in={true} timeout={500}>
            <Paper className={classes.root}>
            <MaterialTable
              title="Jobs"
              columns={columns}
              data={jobsList.map(job => job.fieldData)}
              icons={tableIcons}
              options={{
                pageSize:10,
                filtering: true,
              }}
              localization={{
                body:{
                  filterRow:
                  {
                    filterTooltip: ""
                  },
                  addTooltip: "",
                  
                },
                pagination:{
                  firstTooltip: "",
                  previousTooltip: "",
                  nextTooltip: "",
                  lastTooltip: ""
                },
                toolbar: {
                  searchTooltip: ""
                }
              }}
              onRowClick={(event, rowData) => {
                handleClick(rowData)
              }}
              
            />
            {/*
            <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab style={{width: '100%'}} label="Jobs" {...a11yProps(0)} />
        </Tabs>
      </AppBar>


      
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
          >
        <TabPanel value={value} index={0} dir={theme.direction}>
        <Table className={classes.table}>
              <TableHead>
              <TableRow>
                  <TableCell align="right">Job Type</TableCell>
                  <TableCell align="right">Job Name</TableCell>
                  <TableCell align="right">Customer Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {showResult && jobsList.map(row => (
                  <TableRow key={row.fieldData.__ID} onClick={() => handleClick(row)} className={classes.tableRow}>
                    <TableCell align="right">{row.fieldData.JobType}</TableCell>
                    <TableCell align="right">{row.fieldData.Job_Name}</TableCell>
                    <TableCell align="right">{row.fieldData.Customer_lu}</TableCell>
                  </TableRow>
                ))}
                {showResult && jobsList.length == 0 && (
                  <TableRow>
                    <TableCell align="right">No Jobs to Display</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
                )}
              </TableBody>
            </Table>
        </TabPanel>
      </SwipeableViews>*/}
            
            </Paper>
            </Zoom>
            {!updated && <CircularProgress className={classes.progress} />}
            {updated && <span>Up to date</span>}
            </div>
        )}
        </div>
    </>);

}

export default JobsList;

