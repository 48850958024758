import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Script from 'react-load-script'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  }
}));

export default function IntegrationAutosuggest(props) {
  const classes = useStyles();


  function getAddressObject(address_components) {
    var ShouldBeComponent = {
      home: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      region: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5"
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4"
      ],
      country: ["country"]
    };

    var address = {
      home: "",
      postal_code: "",
      street: "",
      region: "",
      city: "",
      country: ""
    };
    address_components.forEach(component => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });
    return address;
  }


  useEffect(() => {
    

    const handlePlaceSelect = () => {

      // Extract City From Address Object
      const addressObject = autocomplete.getPlace();
      const address = addressObject.address_components;
  
      // Check if address is valid
      if (address) {  

        props.changeChoice(getAddressObject(address));
        
      }
    }

      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById('autocomplete'),
        {},
      );
        // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components and formatted
      // address.
      autocomplete.setFields(['address_components', 'formatted_address']);

      // Fire Event when a suggested name is selected
      autocomplete.addListener('place_changed', handlePlaceSelect);



    }, []);

  return (
    <div className={classes.root}>
        {/* <SearchBar id="autocomplete" onChange={(e) => setQuery(e.target.value) } onRequestSearch={() => console.log('onRequestSearch')} placeholder="" hintText="Search City" value={query}
          style={{
            margin: '0 auto',
            maxWidth: 800,
          }}
        /> */}
        {/* <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyA0S-SHVXNnPwU7Y9ucd0sh2L-o9KPC_sQ&libraries=places"
        /> */}
        <FormControl style={{width: '100%'}}>
                  <InputLabel>Street Address:</InputLabel>
                  <Input type="text" id="autocomplete" onChange={(e) => props.changeValue(e.target.value) } placeholder="" value={props.value} />
          </FormControl>
    </div>
  );
}