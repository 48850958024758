import React, { Component, useState, useEffect } from 'react';
import { useAuth0 } from "../react-auth0-wrapper";
import { Paper, Button, TextField, MenuItem, OutlinedInput, Select, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Camera from 'react-html5-camera-photo';
import ReplayIcon from '@material-ui/icons/Replay';
import appPaths from '../applicationPaths.json';
import theme from '../themes/theme';
import { Redirect } from 'react-router';
import InvoiceMaterialUnitsDropdown from "./Invoice/editInvoice/InvoiceMaterialUnitDropdown";

const useStyles = makeStyles(theme => ({
    progress: {
        width: '100%',
    }
  }));

export default function JobCamera (props) {
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const [mirror, setMirror] = useState(false);
    const [idealFacingMode, setIdealFacingMode] = useState('environment');
    const [showImages, setShowImages] = useState(0);
    const [caption, setCaption] = useState('');
    const { getTokenSilently } = useAuth0();
    const [uploading, setUploading] = useState(false);
    const [open, setOpen] = useState(false);
    const [newImages, setNewImages] = useState(0);
    const [redirectDetails, setRedirectDetails] = useState({set: false, location: "/job-map"});
    const [values, setValues] = useState({
      beforeAfter: 'Before',
      id:''
    });
    const [captionList, setCaptionList] = useState([]);

    useEffect(() => {
      const getCaptions = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/getCaptionList", {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
          });
          const responseData = await response.json();
          console.log(responseData.data);
          if(responseData.message)
          {
            setCaptionList(responseData.data.response.data);
          }
          else
          {
            //alert(responseData);
          }
        } catch (error) {
          console.error(error);
        }
      };
      getCaptions();
    }, [getTokenSilently]);


    const onTakePhoto = (dataUri) => {
        let currentImages = images;
        currentImages.unshift({data: dataUri, description: ''});
        setImages(currentImages);
        let number = showImages;
        setShowImages(number + 1);
        console.log(images.length)
        // Do stuff with the dataUri photo...
      };
      function handleTextFieldChange(textValue)
      {
        setCaption(textValue.target.value);
      }
      function handleClose(type) {
        setValues({id:'',beforeAfter:'Before'});
        setOpen(false);
        
      }
      function handleChange(event) {
        setValues(oldValues => ({
          ...oldValues,
          [event.target.name]: event.target.value,
        }));
      }
      function addImage(__ID, beforeAfter)
      {
        if(beforeAfter)
        {
          setValues({__ID:__ID,beforeAfter:'Before'});
          setOpen(true);
          console.log(values);
        }
        else
        {
          setValues({__ID:__ID,beforeAfter:'After'});
          setOpen(true);
        }
        
      }
      const removeImage = (i) => {
        let currentImages = images;
        currentImages.splice(i, 1);
        setImages(currentImages);
        let number = showImages;
        setShowImages(number - 1);
        
      };
      const uploadImages = () => {
        console.log(images);
        setUploading(true);
        images.forEach((image, i) => {
          var formData = new FormData();
          formData.append('upload', images[i].data);
          formData.append('_idf_job', props.location.state.__ID);
          console.log(props.location.state.currentFeature)
          if(props.location.state.currentFeature != null)
          {
            formData.append('markerId', props.location.state.currentFeature);
          }
          else
          {
            formData.append('markerId', "");
          }
          formData.append('imageDescription', caption);
          formData.append('beforeAfter', values.beforeAfter);
          formData.append('__ID', values.id);
          uploadImage(formData, 0);
        })
      };

      const uploadImage = async (form, i) => {
        try {
          const token = await getTokenSilently();
      
          const response = await fetch(appPaths.apiPath + "/api/uploadImages", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Accept': 'application/json'   // expected data sent back
            },
            body: form
          });
      
          const responseData = await response.json();
          console.log(responseData);
          if(responseData.data.message || i > 9)
          {
            if(i > 9)
            {
              if(window.confirm("Image failed to upload! Would you like to try resending it?"))
              {
                uploadImage(form, 0);
              }
            }
            else
            {
              setNewImages(newImages + 1);
              setImages([]);
              setCaption('');
              handleClose();
              let data = {set: true, to: {pathname: "/job-map", state: {__ID: props.location.state.__ID}}};
              setRedirectDetails(data);
            }
            setUploading(false);
            
          }
          else
          {
            uploadImage(form, i + 1)
          }
          
      
        } catch (error) {
          console.error(error);
          setUploading(false);
        }
      };


    return (
        <div style={{width: '100%', display: 'flex', justifyContent:'center', 'flexDirection': 'column', textAlign: 'center',}}>
            {redirectDetails.set && 
            (<Redirect push to={redirectDetails.to} />
          )}
             {uploading && <><CircularProgress className={classes.progress} /></>}
          {!uploading &&
          <>
        <Paper style={{ margin: theme.spacing(2)}}>
            {images.length == 0 && <div style={{position: 'relative'}}>
            <Camera
                imageType="jpg"
                idealFacingMode = {idealFacingMode}
                isImageMirror={mirror}
                onTakePhoto = { (dataUri) => { onTakePhoto(dataUri); } }
            /><Button onClick={(event) => {if(idealFacingMode == 'environment') {setIdealFacingMode('user'); setMirror(true);} else { setIdealFacingMode('environment'); setMirror(false); }}} variant="contained" style={{position: 'absolute', right: '0', top: '0'}}><ReplayIcon /></Button></div>}
            
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            {images.length > 0 && images.map((row, i) => (
                <div key={i} style={{position: 'relative'}}><img style={{width: '100%'}} src={row.data}>
                </img><Button onClick={(event) => {removeImage(i); setCaption('')}} variant="contained" style={{backgroundColor:"#FF0000", position: 'absolute', right: '0', top: '0'}}>X</Button></div>
            ))}
            {images.length > 0 &&
            <div style={{ width:'100%'}}>
            <Select
                onChange={handleChange}
                value={values.beforeAfter}
                style={{width: '100%'}}
                input={<OutlinedInput name="beforeAfter" id="beforeAfter" />}
              >
                <MenuItem value='Before'>Before</MenuItem>
                <MenuItem value='After'>After</MenuItem>
              </Select>
              <InvoiceMaterialUnitsDropdown
                changeValue={setCaption}
                value={caption}
                changeChoice={() => {}}
                materials={values.beforeAfter  == "Before" ? [...new Set(captionList.filter(caption => caption.fieldData.flag_before == "1").map(material => { return material.fieldData.caption }))] : [...new Set(captionList.filter(caption => caption.fieldData.flag_before != "1").map(material => { return material.fieldData.caption }))]}
                style={{width:'100%'}}
                label="Caption"
                placeholder="Caption"
              />
              {/*
              <TextField
                value={caption}
                onChange={handleTextFieldChange}
                multiline
                style={{ margin: 8 }}
                fullWidth
                className={classes.textField}
                variant="outlined"
                placeholder="Caption">

              </TextField>*/}
              
              <Button variant="contained" style={{margin: theme.spacing(2)}} color="primary" onClick={() => {uploadImages()}}>Upload Image</Button>
            </div>
            }
            </div>

          </Paper>
          </>
          }

          </div>
    );
}