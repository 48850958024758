import React, {useState, useRef, useEffect} from 'react';
import {CircularProgress, Card, List, ListItem, Divider, ListItemText, ListItemIcon, ListItemAvatar, IconButton, Avatar, Checkbox, Switch, FormControlLabel, Badge} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import appPaths from '../../../applicationPaths.json';
import { useAuth0 } from "../../../react-auth0-wrapper";
import RoomIcon from '@material-ui/icons/Room';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import theme from '../../../themes/theme';
import ImageModal from '../../Map/Image';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DoneIcon from '@material-ui/icons/Done';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles(theme => ({
  list: {

  }
}));

export default function InvoiceImages(props) {

    const [loading, setLoading] = useState(true);
    const classes = useStyles();

    const { isAuthenticated, loginWithRedirect, logout, getTokenSilently } = useAuth0();
    const [currentImage, setCurrentImage] = useState(null);
    const [imageOpen, setImageOpen] = useState(false);
    const [currentMarkerIndexes, setCurrentMarkerIndexes] = useState([]);

    const getImages = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/getJobImages", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({id: props.jobId})
          });
    
          const responseData = await response.json();
          if(responseData.message)
          {
            let imageData = responseData.data.response.data;
            imageData.forEach(image => {
              image.changed = false;
            });
            props.changeImages(imageData.sort((a, b) => (a.fieldData._idf_marker > b.fieldData._idf_marker) ? -1 : 1));
            setLoading(false);
            
            //setJobDetails(responseData.data.response.data[0].fieldData);
            //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
          }
          else
          {
              setLoading(false);
            //alert(responseData);
          }
  
        } catch (error) {
          console.error(error);
        }
      };


      useEffect(() => {
        getImages();
      }, []);

      useEffect(() => {
        if(props.images.length > 0 && props.oldPictures.length > 0) {
            props.changeSelectedImages(props.images.filter( image => props.oldPictures.includes(image.fieldData.__ID)));
        } 
      }, [props.images, props.oldPictures]);

    function handleListItemClick(event, image) {
        if(props.selectedImages.includes(image))
        {
            props.changeSelectedImages(props.selectedImages.filter(item => item != image));
        }
        else
        {
            props.changeSelectedImages(prevState => { return [...prevState, image]});
        }
        
      }

    return (
        <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', flexDirection:'column', borderRadius: '0px'}}>
        {!loading && 
            <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px'}}>
                <List className={classes.list}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Select Images to Appear on Invoice
                  </ListSubheader>
                }
                >
                    <>

                        {props.images.map((image, i) =>
                        (
                            
                        <div key={i}>
                            
                        <ListItem
                        button
                        selected={props.selectedImages.includes(image)}
                        onClick={event => handleListItemClick(event, image)}
                        >
                            <ListItemAvatar>
                                <IconButton onClick={(event) => { setCurrentImage(image); setImageOpen(true); event.stopPropagation(); }}>
                                    <Avatar  style={{backgroundImage: 'url(' + appPaths.apiPath + "/api/getFMImage?url=" + image.fieldData.__ID + ')', backgroundSize: 'cover', borderColor: theme.palette.secondary.main, borderWidth: 'thin', borderStyle: 'solid'}} alt="Remy Sharp" /*src={image.fieldData.Picture_oAe}*/>
                                        {props.selectedImages.includes(image) && (
                                            <Avatar style={{background: "rgba(213, 243, 245, .5)"}}>
                                            <DoneIcon fontSize="large" color="secondary" />
                                            </Avatar>
                                        )}
                                    </Avatar>
                                </IconButton>
                            </ListItemAvatar>
                            <ListItemText primary={image.fieldData.Description}/>
                            
                                {props.imageMapData[image.fieldData._idf_marker] != null && 
                                    <>
                                        {props.imageMapData[image.fieldData._idf_marker].type == "Point" && <ListItemIcon><Badge color="primary" badgeContent={props.imageMapData[image.fieldData._idf_marker].label}><RoomIcon style={{color: 'red'}}/></Badge></ListItemIcon>}
                                        {props.imageMapData[image.fieldData._idf_marker].type == "Polygon" && <ListItemIcon><ChangeHistoryIcon style={{color: props.imageMapData[image.fieldData._idf_marker].color}}/></ListItemIcon>}

                                    </>
                                }
                                {props.imageMapData[image.fieldData._idf_marker] == null && 
                                <ListItemIcon>
                                    <WorkOutlineIcon />
                                </ListItemIcon>
                                }
                            <FormControlLabel
                                    value={image.fieldData.BeforeAfter}
                                    control={<Switch checked={image.fieldData.BeforeAfter == "After"} disabled color="secondary" />}
                                    label={image.fieldData.BeforeAfter}
                                    labelPlacement="bottom"
                                  />
                            
                        </ListItem>
                        <Divider />
                        
                        </div>
                        )
                        )}
                    </>
                </List>
            </Card>
        }
        <div>
        {loading && <CircularProgress></CircularProgress>}
        </div>
        <ImageModal editable={false} open={imageOpen} image={currentImage} changeOpen={() => setImageOpen(false)} />
        </div>
    )
}