// src/components/NavBar.js

import React from "react";
import { useAuth0 } from "../react-auth0-wrapper";
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import logo from '../assets/images/logo.png';

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout, user, loading } = useAuth0();

  alert("Height: " + window.innerHeight + "\nWidth:" + window.innerWidth);

  return (
    <div>
      Hello world
    </div>
  );
};

export default NavBar;

