import React, { Component, useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-wrapper";
import { createMuiTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Fab from '@material-ui/core/Fab';
import { green, red } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from '../../themes/theme';
import appPaths from '../../applicationPaths.json';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import RoomIcon from '@material-ui/icons/Room';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import ImageModal from '../Map/Image';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DoneIcon from '@material-ui/icons/Done';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import NewTech from './NewTech.js';
import Modal from '@material-ui/core/Modal'
import _ from 'lodash';
import { ListItemIcon, FormControl } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';



const useStyles = makeStyles(theme => ({
    
  }));

export default function JobTechs(props) {
    const [contentLoading, setContentLoading] = useState(true);
    const classes = useStyles();
    const { isAuthenticated, loginWithRedirect, logout, getTokenSilently, user } = useAuth0();
    const [techList, setTechList] = useState([]);
    const [allTechList, setAllTechList] = useState([]);
    const [newTech, setNewTech] = useState(false);
    const [permissionType, setPermissionType] = useState([]);


    useEffect(() => {
        const callApi = async () => {
            try {
              const token = await getTokenSilently();
              const response = await fetch(appPaths.apiPath + "/api/getJobTechs", {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',  // sent request
                  'Accept':       'application/json'   // expected data sent back
                },
                body: JSON.stringify({jobId: props.location.state.__ID})
              });
              const responseData = await response.json();
              if(responseData.message)
              {
                /*responseData.data.forEach(tech => {
                    fetch(appPaths.apiPath + "/api/getUserByFmId", {
                        method: 'POST',
                        headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',  // sent request
                        'Accept':       'application/json'   // expected data sent back
                        },
                        body: JSON.stringify({id: tech.fieldData.__ID})
                    })
                    .then(res => { return res.json() })
                    .then(res => { tech.auth0 = res.data; setTechList(prevState => [...prevState.filter(tech1 => tech1.recordId !== tech.recordId), tech]); })
                })*/
                setTechList(responseData.data);

                setContentLoading(false);
              }
              else
              {
                //alert(responseData);
              }
    
            } catch (error) {
              console.error(error);
            }
          };
            callApi();
    }, [getTokenSilently, props.location.state.__ID]);

    useEffect(() => {
      const callApi = async () => {
          try {
            const token = await getTokenSilently();
            const response = await fetch(appPaths.apiPath + "/api/getAllTechs", {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
              },
            });
            const responseData = await response.json();
            if(responseData.message)
            {
              setAllTechList(responseData.data);
            }
            else
            {
              //alert(responseData);
            }
  
          } catch (error) {
            console.error(error);
          }
        };
          callApi();
  }, [getTokenSilently, props.location.state.__ID]);

    const handleNewTech = (techs) => {
        setNewTech(false);
        setContentLoading(true);
        const callApi = async () => {
          try {
            const token = await getTokenSilently();
            const response = await fetch(appPaths.apiPath + "/api/setJobTechs", {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
              },
              body: JSON.stringify({jobId: props.location.state.__ID, techs: techs})
            });
            const responseData = await response.json();
            if(responseData.message)
            {
              /*responseData.data.forEach(tech => {
                  fetch(appPaths.apiPath + "/api/getUserByFmId", {
                      method: 'POST',
                      headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json',  // sent request
                      'Accept':       'application/json'   // expected data sent back
                      },
                      body: JSON.stringify({id: tech.fieldData.__ID})
                  })
                  .then(res => { return res.json() })
                  .then(res => { tech.auth0 = res.data; setTechList(prevState => [...prevState.filter(tech1 => tech1.recordId !== tech.recordId), tech]); })
              })*/
              setTechList(responseData.data);

              setContentLoading(false);
            }
            else
            {
              setContentLoading(false);
              //alert(responseData);
            }
  
          } catch (error) {
            console.error(error);
          }
        };
          callApi();
    };

    const deleteTech = (tech) => {
      let newTechList = techList.filter( (currentTech) => { 
        return tech.fieldData.__ID !== currentTech.fieldData.__ID;
       });

       

       handleNewTech(newTechList);
    }

    const handleChange = (event) => {
      let newTechList = techList;

      newTechList.push(event.target.value);

       handleNewTech(newTechList);
    }

    useEffect(() => {
      const getPermissions = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/getUserPermissions", {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
    
          const responseData = await response.json();
          setPermissionType(responseData);
        }
        catch (error) {
          console.error(error);
        }
      };
      getPermissions();
    }, [getTokenSilently, user])




    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width:'100%'}}>
                        {!contentLoading &&
                <>
                    <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column'}}>
                        
                        <List>
                          <ListItem>
                            <ListItemText primary="Techs Assigned to Job" />
                          </ListItem>
                        {techList.map(tech => {
                            return (
                                <div key={tech.fieldData.__ID}>
                                      <Divider />
                                    <ListItem button>
                                        <ListItemAvatar>
                                            <Avatar src={"Auth0" in tech ? tech.Auth0.picture : ""} />
                                        </ListItemAvatar>
                                        <ListItemText primary={tech.fieldData.Name_combined_ae}></ListItemText>
                                        <IconButton onClick={() => { deleteTech(tech) }}><DeleteIcon /></IconButton>
                                    </ListItem>

                                </div>
                                
                            );
                        })}
                        <Divider />
                        <ListItem>
                        {/* <Button onClick={() => {
                            //props.setMaterialList(prevState => {return [...prevState, {name:"", price:"", qty: "", units: ""}]})
                            setNewTech(true);
                        }} variant="outlined" color="primary">Change Techs</Button> */}
                        <FormControl
                          style={{width: '100%'}}
                         >
                          <InputLabel>Add Tech</InputLabel>
                          <Select
                              style={{minHeight: '49px', maxWidth:'100%'}}
                              onChange={handleChange}
                              value={''}
                              
                              displayEmpty={true}>
                              {allTechList.filter( tech => !techList.map(currentTech => currentTech.fieldData.__ID).includes(tech.fieldData.__ID) ).map(tech => 
                                  <MenuItem key={tech.fieldData.__ID} value={tech}>
                                      <ListItemAvatar>
                                          <Avatar src={"Auth0" in tech ? tech.Auth0.picture : ""} />
                                      </ListItemAvatar>
                                      <ListItemText primary={tech.fieldData.Name_combined_ae}>

                                      </ListItemText>
                                  </MenuItem>
                              )}
                          </Select>
                          </FormControl>
                        </ListItem>
                        </List>
                        
                    </Card>
                    <NewTech currentlySelected={techList} __ID={props.location.state.__ID} addTech={handleNewTech} open={newTech} />

                </>
            }
            {contentLoading && <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', borderRadius: '0px'}}><CircularProgress></CircularProgress></div>}
        </div>
    );
}