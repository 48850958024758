import React, { useState, useEffect } from 'react';
import {CircularProgress, Button, Select, MenuItem, OutlinedInput, TextField, Zoom} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import theme from '../themes/theme';
import { useAuth0 } from "../react-auth0-wrapper";
import appPaths from '../applicationPaths.json';
import { Redirect } from 'react-router';
import InvoiceMaterialUnitsDropdown from "./Invoice/InvoiceMaterialUnitDropdown";


export default function JobUpload (props) {
    const { isAuthenticated, loginWithRedirect, logout, getTokenSilently } = useAuth0();
    const [selectedFile, setSelectedFile] = useState(null);
    const [loaded, setLoaded] = useState(true);
    const [buttonText, setButtonText] = useState('Select Image');
    const [isImage, setIsImage] = useState(false);
    const [beforeAfter, setBeforeAfter] = useState('Before');
    const [caption, setCaption] = useState('');
    const [redirectDetails, setRedirectDetails] = useState({set: false, location: "/job-map"});
    const [captionList, setCaptionList] = useState([]);

    useEffect(() => {
      const getCaptions = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/getCaptionList", {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
          });
          const responseData = await response.json();
          console.log(responseData.data);
          if(responseData.message)
          {
            setCaptionList(responseData.data.response.data);
          }
          else
          {
            //alert(responseData);
          }
        } catch (error) {
          console.error(error);
        }
      };
      getCaptions();
    }, [getTokenSilently]);
    const onChangeHandler = event=>{
        if(event.target.files.length > 0)
        {

          setSelectedFile(event.target.files[0]);
          setButtonText(event.target.files[0].name);
          setIsImage(true);
        }
        else
        {

          setSelectedFile(false);
          setButtonText('Select Image');
          setIsImage(false);
        }
    
    }

    const handleChange = (event) => {
      setBeforeAfter(event.target.value);
    };

    const onClickHandler = () => {
      setLoaded(false);
        const data = new FormData();
        data.append('file', selectedFile);
        data.append('_idf_job', props.location.state.__ID);
        data.append('BeforeAfter', beforeAfter);
        data.append('Description', caption);
        if(props.location.state.currentFeature != null)
        {
          data.append('_idf_marker', props.location.state.currentFeature);
        }
        else
        {
          data.append('_idf_marker', "");
        }

        uploadImage(data, 0);
    };

    const uploadImage = async (form, i) => {
        try {
          const token = await getTokenSilently();
      
          const response = await fetch(appPaths.apiPath + "/api/uploadImageFile", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Accept': 'application/json'   // expected data sent back
            },
            body: form
          });
      
          const responseData = await response.json();
          console.log(responseData);
          if(responseData.data.message || i > 9)
          {
            if(i > 9)
            {
              if(window.confirm("Image failed to upload! Would you like to try resending it?"))
              {
                uploadImage(form, 0);
              }
            }
            else
            {
              let data = {set: true, to: {pathname: "/job-map", state: {__ID: props.location.state.__ID}}};
              setRedirectDetails(data);
            }
            
          }
          else
          {
            uploadImage(form, i + 1)
          }
          
      
        } catch (error) {
          console.error(error);
        }
      };

      const handleTextFieldChange = (event) => {
        setCaption(event.target.value);
      };

    return (
        <div style={{width: '100%', display: 'flex', justifyContent:'center',}}>
          {redirectDetails.set && 
            (<Redirect push to={redirectDetails.to} />
          )}
        {loaded &&
        <Card style={{ padding:'25px', width: '100%', display: 'flex', justifyContent:'center', flexDirection:'column', alignContent: 'center', textAlign: 'center'}}>
          <Zoom in={true}
        {...({ timeout: 500})}>
              <div style={{padding: theme.spacing(1)}}>
                <input
                accept="image/*"
                //className={classes.input}
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={onChangeHandler}
                />
                <label htmlFor="raised-button-file">
                <Button variant="contained" color="secondary" component="span" style={{width: '100%'}} /*className={classes.button}*/>
                    {buttonText}
                </Button>
                </label>
              </div>
            </Zoom>
            <Zoom in={true}
        {...({ timeout: 1000})}>
            <div style={{padding: theme.spacing(1)}}>
              <Select
                onChange={handleChange}
                value={beforeAfter}
                style={{width: '100%'}}
                input={<OutlinedInput name="beforeAfter" id="beforeAfter" />}
              >
                <MenuItem value='Before'>Before</MenuItem>
                <MenuItem value='After'>After</MenuItem>
              </Select>
            </div>
            </Zoom>
            <Zoom in={true}
              {...({ timeout: 1500})}>
              <div style={{padding: theme.spacing(1)}}>
              <InvoiceMaterialUnitsDropdown
                changeValue={setCaption}
                value={caption}
                changeChoice={() => {}}
                materials={beforeAfter  == "Before" ? [...new Set(captionList.filter(caption => caption.fieldData.flag_before == "1").map(material => { return material.fieldData.caption }))] : [...new Set(captionList.filter(caption => caption.fieldData.flag_before != "1").map(material => { return material.fieldData.caption }))]}
                style={{width:'100%'}}
                label="Caption"
                placeholder="Caption"
              />
              </div>
            </Zoom>
            
            <Zoom in={true}
        {...({ timeout: 2000})}>
            <div style={{padding: theme.spacing(1)}}>
            <Button onClick={onClickHandler} disabled={!isImage} variant="contained" color="secondary" style={{width: '100%'}}>
                Submit
            </Button>
            </div>
            </Zoom>
            
        </Card>
        }
        {!loaded && <CircularProgress></CircularProgress>}
        </div>
    )
}