import React, { useState, useEffect, } from 'react';
import {CircularProgress, IconButton,Card, Toolbar, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, ListItemIcon, Checkbox } from '@material-ui/core';
import theme from '../../themes/theme';
import { CompactPicker } from 'react-color';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PanToolIcon from '@material-ui/icons/PanTool';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import Modal from '@material-ui/core/Modal';
import appPaths from '../../applicationPaths.json';
import { useAuth0 } from "../../react-auth0-wrapper";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import EditIcon from '@material-ui/icons/Edit';
import { Shake, ShakeLittle } from 'reshake'
import ImageModal from './Image';
import DeleteIcon from '@material-ui/icons/Delete';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Typography from '@material-ui/core/Typography';
import MarkerImage from '../../assets/images/marker.png';
import PolygonImage from '../../assets/images/polygon.png';
import HandImage from '../../assets/images/hand.png'

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      margiRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    paper: {
      margin: theme.spacing(3),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100%',
    },
    grow: {
      flexGrow: 1,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    list: {
      maxWidth: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    label: {
      // Aligns the content of the button vertically.
      flexDirection: 'column',
      fontSize: '14px'
    },
  }));

export default function MyComponent(props)
{

    const [description, setDescription] = useState('');
    const [label, setLabel] = useState('');
    const classes = useStyles();
    const [openColor, setOpenColor] = useState(false);
    const {getTokenSilently} = useAuth0();
    const [stepImages, setStepImages] = useState([]);
    const [currentImage, setCurrentImage] = useState(null);
    const [imageOpen, setImageOpen] = useState(false);
    const [checkedBA, setCheckedBA] = useState([0]);
    const [editMode, setEditMode] = useState(false);
    const [checked, setChecked] = useState([0]);
    const [id, setId] = useState("");

    const changeColor = (color, event) => {
        props.currentFeature.setProperty('color', color.hex);
        //props.mapRef.current.data.setStyle(function(feature) {return {fillColor: feature.getProperty('color'), strokeColor: feature.getProperty('color')}; });
      };

      const handleDeleteClick = (e, image) => {
        e.stopPropagation();
        image.deleted = true;
        image.changed = true;
        props.setChanges(true);
        console.log('image deleted');
      };

      useEffect(() => {
        const newChecked = [0];
        if(props.currentFeature != null)
          {
        props.images.forEach(element => {
          const currentIndex = checkedBA.indexOf(element);
          
              if(element.fieldData.BeforeAfter == "After")
              {
                  newChecked.push(element);
              }
          

        });
      
      }
      setCheckedBA(newChecked);

  }, [props.currentFeature, props.detailsTab]);

  useEffect(() => {
    if(props.currentFeature  != null)
    {
        setId(props.currentFeature.getProperty('id'));
    }
    const newChecked = [0];
    props.images.forEach(element => {
    const currentIndex = checked.indexOf(element);
    if(props.currentFeature != null)
    {
        if(element.fieldData._idf_marker == props.currentFeature.getProperty('id'))
        {
            newChecked.push(element);
        }
    }

    });
    

setChecked(newChecked);

}, [props.currentFeature, props.detailsTab]);
  

    useEffect(() => {
        if(props.currentFeature != null)
        {
            if(props.currentFeature.getProperty('description') != null)
            {
                setDescription(props.currentFeature.getProperty('description'));
            }
            else
            {
                setDescription('');
            }
            if(props.currentFeature.getProperty('label') != null)
            {
                setLabel(props.currentFeature.getProperty('label'));
            }
            else
            {
              setLabel('');
            }
            
        }
        
    }, [props]);

      const deleteCurrentElement = () => {
        if(props.currentFeature != null)
        {
          var markerId = props.currentFeature.getProperty('id');
          props.images.filter(image => image.fieldData._idf_marker == markerId).forEach(image => {
            image.fieldData._idf_marker = "";
            image.changed = true;
          });
          props.mapRef.current.data.remove(props.currentFeature);
          props.changeCurrentFeature(null);
        }
    
      };
      const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
          value.fieldData._idf_marker = props.currentFeature.getProperty('id');
          value.changed = true;
        } else {
          newChecked.splice(currentIndex, 1);
          value.fieldData._idf_marker = "";
          value.changed = true;
        }
    
        setChecked(newChecked);
      };


      const changeText = (event) =>
      {
        props.currentFeature.setProperty('description', event.target.value);
        setDescription(event.target.value);
      };

      const changeLabelText = (event) =>
      {
        props.currentFeature.setProperty('label', event.target.value);
        setLabel(event.target.value);
      };

      const handleColorClose = () => {
        setOpenColor(false);
      }
      const handleImageClose = () => {
        setImageOpen(false);
      }

      const handleBAToggle = value => (e) => {
        e.stopPropagation();
        const currentIndex = checkedBA.indexOf(value);
        const newChecked = [...checkedBA];
    
        if (currentIndex === -1) {
          newChecked.push(value);
          value.fieldData.BeforeAfter = "After";
          value.changed = true;
        } else {
          newChecked.splice(currentIndex, 1);
          value.fieldData.BeforeAfter = "Before";
          value.changed = true;
        }
    
        setCheckedBA(newChecked);
        props.setChanges(true);
      };


    return (
      <>
        <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px'}}>
                    {props.currentFeature == null && <>
                        <p>Select <img src={PolygonImage} style={{width : '25px'}} /> to draw roof section.<br />
                        Select <img src={MarkerImage} style={{width : '25px'}} /> to add markers.  <br />
                        Select a marker or roof section using <img src={HandImage} style={{width : '25px'}} /> to add details.</p>
                      </>
                    }
                    {props.currentFeature != null && <>
                      {/*<Typography variant="h5">
                        {props.currentFeature.getGeometry().getType() === "Polygon" ? "Roof Section" : "Marker " + props.label}
                    </Typography>*/}
                    You have selected {props.currentFeature.getGeometry().getType() === "Polygon" ? "Roof Section" : "Marker " + props.label}
                      <br />
                    <Card style={{width:'100%', display: 'flex', justifyContent: 'center', textAlign: 'center', borderRadius: '0px'}}>
                      <Toolbar style={{width:'100%', display: 'flex', justifyContent: 'center', textAlign: 'center', borderRadius: '0px'}}>
                      <div className={classes.grow} />
                        <IconButton classes={{label: classes.label}} color="secondary" onClick={() => { props.setNewPicOpen(true); }}>
                          
                          <AddAPhotoIcon />
                          Add Image
                        </IconButton>

                        
                        {props.currentFeature.getGeometry().getType() === "Polygon" &&
                        <>
                        <div className={classes.grow} />
                          <IconButton classes={{label: classes.label}} color="secondary" onClick={() => {setOpenColor(true)}}>
                            <ColorLensIcon />
                            Color
                          </IconButton>
                        </>}


                        <div className={classes.grow} />
                        <IconButton classes={{label: classes.label}} color="secondary" onClick={() => {props.changeCurrentFeature(null)}}>
                          
                          <PanToolIcon />
                          Unselect
                        </IconButton>

                        <div className={classes.grow} />
                        <IconButton classes={{label: classes.label}} color="secondary" onClick={deleteCurrentElement}>
                          
                          <DeleteForeverIcon />
                          Delete
                        </IconButton>
                        <div className={classes.grow} />

                        {/*
                        <div className={classes.grow} />
                        <IconButton color="secondary" onClick={() => setEditMode(!editMode)}
                        >
                          <EditIcon />
                        </IconButton>
                        */}
                      </Toolbar>

                    </Card>
                    <br />
                    {/*<div style={{width: '100%'}}>
                            <TextField
                            style={{width: '90%'}}
                            id="standard-name"
                            label="Label"
                            value={label}
                            onChange={changeLabelText}
                            className={classes.textField}
                            multiline
                            />
                    </div>*/}
                            <div style={{width: '100%'}}>
                            <TextField
                            style={{width: '90%'}}
                            id="standard-name"
                            label={props.currentFeature.getGeometry().getType() === "Polygon" ? "Add Name" : "Add Description"}
                            value={description}
                            onChange={changeText}
                            className={classes.textField}
                            multiline
                            />
                            </div>
                              {props.currentFeature.getProperty('area') != undefined && <Card style={{margin: theme.spacing(1), }}>
                              <span>Roof Section Area = <span style={{color: theme.palette.secondary.main}}>{props.currentFeature.getProperty('area').toFixed(2)}</span> sq. ft</span>
                              </Card>}
                              {props.currentFeature.getProperty('perimeter') != undefined && <Card style={{margin: theme.spacing(1), }}>
                              <span>Roof Section Perimeter = <span style={{color: theme.palette.secondary.main}}>{props.currentFeature.getProperty('perimeter').toFixed(2)}</span> ft</span>
                              </Card>}
                            <div style={{width: '100%'}}>
                            <List className={classes.list}>
                              {props.loading && <CircularProgress></CircularProgress>}
                              {!props.loading && <>
                                <br />
                                <Divider />
                              {props.images.map((image, i) => (
                                
                                <div key={image.fieldData.__ID}>
                                  {image.fieldData._idf_marker === props.currentFeature.getProperty('id') && 
                                  <>
                                    <ListItem button onClick={() => { setCurrentImage(image); setImageOpen(true) }}>
                                    {props.currentFeature != null && editMode &&
                                      <ListItemIcon>
                                      <Checkbox
                                          edge="start"
                                          checked={checked.indexOf(image) !== -1}
                                          tabIndex={-1}
                                          disableRipple
                                          onClick={handleToggle(image)}
                                      />
                                      </ListItemIcon>
                                      }
                                      <ListItemAvatar>
                                        <IconButton>
                                          <Avatar  style={{backgroundColor:theme.palette.secondary.main}} alt={image.fieldData.Description} src={appPaths.apiPath + "/api/getFMImage?url=" + image.fieldData.__ID} />
                                        </IconButton>
                                      </ListItemAvatar>
                                      <ListItemText primary={image.fieldData.Description} />
                                      
                                      <FormControlLabel
                                        value={image.fieldData.BeforeAfter}
                                        control={<Switch checked={checkedBA.indexOf(image) !== -1} onClick={handleBAToggle(image)} color="secondary" />}
                                        label={image.fieldData.BeforeAfter}
                                        labelPlacement="bottom"
                                      />
                                      <IconButton button onClick={(e) => handleDeleteClick(e, image)}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </ListItem>
                                    <Divider />
                                  </>
                                  }
                                </div>
                              ))}
                              </>}
                            </List>
                    </div>

                </>}
                
                </Card>
                <Modal className={classes.modal} onClose={handleColorClose} open={openColor}><div><CompactPicker onChange={ changeColor }/></div></Modal>
                <ImageModal
                  editable={true}
                  changeDescription={(description) =>
                  {
                    var newImage = currentImage;
                    newImage.fieldData.Description = description;
                    newImage.changed = true;
                    props.images.filter(image => image === currentImage).forEach(image => { props.setImage(image, newImage); });
                  }}
                  changeCurrentImage={setCurrentImage}
                  open={imageOpen}
                  image={currentImage}
                  changeOpen={() => setImageOpen(false)}
                />
                </>
    );
}