import React, { Component, useState, useEffect } from 'react';
import { useAuth0 } from "../react-auth0-wrapper";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import JobImages from './JobImages';
import InvoiceImageChoices from './InvoiceImageChoices';
import InvoiceImages from './InvoiceImages';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  textField: {
    width: '100%',
  },
}));


const Invoice = (props) => {
    const classes = useStyles();
    const [invoiceImages, setInvoiceImages] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [values, setValues] = useState({description: '', amount: 0});
    const [permissionType, setPermissionType] = useState([]);

    const addImage = (image) => {
        setInvoiceImages(prevState => { return [...prevState, image]});
    };
    const removeImage = (newImageList) => {
        setActiveStep(prevState => { return 0});
        setInvoiceImages(prevState => { return prevState.filter((image, i) => i != newImageList)});
    };
    const nextImage= () => {
        setActiveStep(prevState => { return prevState + 1});
    };
    const prevImage= () => {
        setActiveStep(prevState => { return prevState - 1});
    };
    const handleChange = (newValue) => (event) => {
        setValues({ ...values , [newValue]: event.target.value })
    }
    const submitInvoice = () => {
        alert("THe ability to submit invoices has not been added yet!");
    };

    return (
        <>
            
            <div className={classes.root}>
                <Grid justify="center" container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>Please Select Images for Invoice<InvoiceImageChoices __ID={props.location.state.__ID} addImage={addImage} /></Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>Selected Images for Invoice<InvoiceImages handleNext={nextImage} handleBack={prevImage} activeStep={activeStep} images={invoiceImages} removeImage={removeImage} /></Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Description"
                                multiline
                                value={values.description}
                                onChange={handleChange('description')}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <TextField
                                id="outlined-number"
                                label="Amount"
                                value={values.amount}
                                onChange={handleChange('amount')}
                                type="number"
                                className={classes.textField}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3} xl={2}>
                        <Paper className={classes.paper}>
                            <Button onClick={() => {submitInvoice()}} style={{width:'100%'}} variant="contained" color="primary">
                                Submit Invoice
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            
            </div>
        </>
    );

};

export default Invoice;