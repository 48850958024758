import React, { useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-wrapper";
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import { green, red } from '@material-ui/core/colors';
import { CircularProgress, Card, Typography, CardContent, List, ListItemText, ListItem } from '@material-ui/core';
import theme from '../../themes/theme';
import appPaths from '../../applicationPaths.json';
import openSocket from 'socket.io-client';
import TimecardDetails from './TimecardDetails';




const theme2 = createMuiTheme({
    palette: {
      primary: { main : '#3f51b5'},
      secondary: { main : '#f44336'}
    },
  });

const useStyles = makeStyles(theme => ({
    fab: {
      margin: theme.spacing(1),
      width: '150px',
      height: '150px',
      fontSize:'20px',
      fontWeight: 'bold'
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    card: {
      minWidth: 275,
      textAlign: 'center',
      padding: '25px'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }));





export default function Timecard(props) {
  
    const classes = useStyles();
    const [clockedIn, setClockedIn] = useState(false);
    const [loading1, setLoading1] = useState(true);
    const [infoLoaded, setInfoLoaded] = useState(false);
    const { isAuthenticated, loginWithRedirect, logout, getTokenSilently, user, loading } = useAuth0();
    const [timecardData, setTimecardData] = useState(null);
    const [newLoading, setNewLoading] = useState(false);





  useEffect(() => {
    const connect = async () => {
      let token = await getTokenSilently();
      const socket = await openSocket(appPaths.apiPath, {'query': 'token=' + token });
      socket.on('update', (newData) => {
        setTimecardData(newData.data);
        localStorage.setItem('timecardData', JSON.stringify(newData.data));
        if(newData.data.fieldData.TimeEnd_t != "")
            {
                setClockedIn(false);
            }
            else
            {
                setClockedIn(true);
            }
      })
    
  }
    if(!loading && isAuthenticated)
    {
      connect();
    }
  }, [loading, isAuthenticated, getTokenSilently])


    const changeStatus = () => {
        if(!loading1 && timecardData != null)
        {
          localStorage.removeItem('timecardData');
            setLoading1(true);
            const getTimecardData = async () => {
                try {
                  const token = await getTokenSilently();
                  const response = await fetch(appPaths.apiPath + "/api/clockInOut", {
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json',  // sent request
                      'Accept':       'application/json'   // expected data sent back
                    },
                    body: JSON.stringify(timecardData)
                  });
                  
                  const responseData = await response.json();
                  if(responseData.message)
                  {
                    
                    setInfoLoaded(false);
                    //setJobDetails(responseData.data.response.data[0].fieldData);
                    //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
                  }
                  else
                  {
                    setInfoLoaded(false);
                    //alert(responseData);
                  }
          
                } catch (error) {
                  console.error(error);
                }
              };
              getTimecardData();
        }
        
    }

    const createTimecard = (data) => {
      setNewLoading(true);
      localStorage.removeItem('timecardData');
      setLoading1(true);
      const createTimecard1 = async (data) => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/createTimecard", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify(data)
          });
          
          const responseData = await response.json();
          if(responseData.message)
          {
            
            setInfoLoaded(false);
            setNewLoading(false);
            //setJobDetails(responseData.data.response.data[0].fieldData);
            //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
          }
          else
          {
            
            //alert(responseData);
          }
  
        } catch (error) {
          console.error(error);
        }
      };
      createTimecard1(data);
    };

    useEffect(() => {
        if(!infoLoaded)
        {
          if(localStorage.getItem('timecardData'))
          {
            setInfoLoaded(true);
            setLoading1(false);
            setTimecardData(JSON.parse(localStorage.getItem('timecardData')));
            if(JSON.parse(localStorage.getItem('timecardData')).fieldData.TimeEnd_t != "")
            {
              setClockedIn(false)
            }
            else
            {
              setClockedIn(true);
            }
          }
            const getTimecardData = async () => {
                try {
                  const token = await getTokenSilently();
                  const response = await fetch(appPaths.apiPath + "/api/getTimecardData", {
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json',  // sent request
                      'Accept':       'application/json'   // expected data sent back
                    },
                    body: null
                  });
                  
                  const responseData = await response.json();
                  if(responseData.message)
                  {
                    if(responseData.data.fieldData.TimeEnd_t != "")
                    {
                      setClockedIn(false)
                    }
                    else
                    {
                      setClockedIn(true);
                    }
                    setInfoLoaded(true);
                    setLoading1(false);
                    localStorage.setItem('timecardData', JSON.stringify(responseData.data));
                    setTimecardData(responseData.data);
                    //setJobDetails(responseData.data.response.data[0].fieldData);
                    //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
                  }
                  else
                  {
                    localStorage.removeItem('timecardData');
                    setInfoLoaded(true);
                    setTimecardData(null);
                    //alert(responseData);
                  }
          
                } catch (error) {
                  console.error(error);
                }
              };
              getTimecardData();
        }
    }, [getTokenSilently, infoLoaded])

    return (
        <div style={{marginTop: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          {!newLoading &&
          <>
          <Card className={classes.card}>
          <CardContent>
            {
              !loading1 && timecardData &&
              <Typography variant="h5" color="primary" gutterBottom>
              Welcome, {timecardData.fieldData.NameStaff_lu}!
              </Typography>
            }

            {!loading1 && timecardData &&

              <List>
                <ListItem>
                  <ListItemText primary={'Work Type: ' + timecardData.fieldData.WorkType} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={'Job: ' + timecardData.fieldData.NameJob_lu} />
                </ListItem>
                <ListItem>
                  {timecardData.fieldData.ChangeOrderName_Lu &&
                  <ListItemText primary="Change Order" secondary={timecardData.fieldData.ChangeOrderName_Lu} />
                  }
                </ListItem>
                  {!clockedIn &&
                  <TimecardDetails createTimecard={createTimecard} timecardData={timecardData} />
                  }
              </List>
            }
            
            {!loading && !timecardData && <TimecardDetails createTimecard={createTimecard} timecardData={timecardData} />}
            </CardContent>
            
            {timecardData &&
            <ThemeProvider theme={theme2}>
                    <Fab onClick={() => { changeStatus() }} style={!clockedIn ? {background: '#3f51b5', color: 'white'} : {background: '#f44336', color: 'white'}} className={classes.fab}>
                        {loading1 && 
                        <ThemeProvider theme={theme}>
                            <CircularProgress ></CircularProgress>
                        </ThemeProvider>
                        }
                        {!loading1 &&
                            <>
                                {!clockedIn ? "CLOCK IN" : "CLOCK OUT"}
                            </>
                        }
                    </Fab>
            </ThemeProvider>
            }
            </Card>
            </>
          }
          {newLoading && <CircularProgress></CircularProgress>}
        </div>
    );
}