import React, { useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-wrapper";
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import { green, red } from '@material-ui/core/colors';
import { CircularProgress, Card, Typography, CardContent, List, ListItemText, ListItem } from '@material-ui/core';
import theme from '../../themes/theme';
import appPaths from '../../applicationPaths.json';
import openSocket from 'socket.io-client';
import TimecardDetails from './TimecardDetails';




const theme2 = createMuiTheme({
    palette: {
      primary: green,
      secondary: red
    },
  });

const useStyles = makeStyles(theme => ({
    fab: {
      margin: theme.spacing(1),
      width: '150px',
      height: '150px',
      fontSize:'20px',
      fontWeight: 'bold'
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    card: {
      minWidth: 275,
      textAlign: 'center',
      padding: theme.spacing(2)
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }));





export default function Timecard(props) {
  
    const classes = useStyles();
    const [clockedIn, setClockedIn] = useState(false);
    const [loading1, setLoading1] = useState(true);
    const [infoLoaded, setInfoLoaded] = useState(false);
    const { isAuthenticated, loginWithRedirect, logout, getTokenSilently, user, loading } = useAuth0();
    const [timecardData, setTimecardData] = useState(null);
    const [newLoading, setNewLoading] = useState(false);


  useEffect(() => {
    const connect = async () => {
      let token = await getTokenSilently();
      const socket = await openSocket(appPaths.apiPath, {'query': 'token=' + token });
      socket.on('update', (newData) => {
        setTimecardData(newData.data);
        localStorage.setItem('timecardData', JSON.stringify(newData.data));
        if(newData.data.fieldData.TimeEnd_t != "")
            {
                setClockedIn(false);
            }
            else
            {
                setClockedIn(true);
            }
      })
  }
    if(!loading && isAuthenticated)
    {
      connect();
    }
  }, [loading, isAuthenticated, getTokenSilently])

    const changeStatus = () => {
      if(!loading1)
      {
        if(timecardData == null)
        {
          setNewLoading(true);
          localStorage.removeItem('timecardData');
          setLoading1(true);
          const createTimecard1 = async (data) => {
            try {
              const token = await getTokenSilently();
              const response = await fetch(appPaths.apiPath + "/api/createTimecard", {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',  // sent request
                  'Accept':       'application/json'   // expected data sent back
                },
                body: JSON.stringify(data)
              });
              
              const responseData = await response.json();
              if(responseData.message)
              {
                setInfoLoaded(false);
                setNewLoading(false);
                //setJobDetails(responseData.data.response.data[0].fieldData);
                //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
              }
              else
              {
                //alert(responseData);
              }
            } catch (error) {
              console.error(error);
            }
          };
          let data = {}
          data._idf_job = props.jobId;
          data._idf_changeOrder = "";
          data.WorkType = "";
          data.flag_ChangeOrder = "";
          data.SystemTask_Lu = "";
          createTimecard1(data);
        }
        else if(timecardData.fieldData._idf_job != props.jobId)
        {
          setNewLoading(true);
          localStorage.removeItem('timecardData');
          setLoading1(true);
          const createTimecard1 = async (data) => {
            try {
              const token = await getTokenSilently();
              const response = await fetch(appPaths.apiPath + "/api/createTimecard", {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',  // sent request
                  'Accept':       'application/json'   // expected data sent back
                },
                body: JSON.stringify(data)
              });
              
              const responseData = await response.json();
              if(responseData.message)
              {
                setInfoLoaded(false);
                setNewLoading(false);
                //setJobDetails(responseData.data.response.data[0].fieldData);
                //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
              }
              else
              {
                //alert(responseData);
              }
            } catch (error) {
              console.error(error);
            }
          };
          let data = {}
          data._idf_job = props.jobId;
          data._idf_changeOrder = "";
          data.WorkType = "";
          data.flag_ChangeOrder = "";
          data.SystemTask_Lu = "";
          createTimecard1(data);
        }
        else
        {
          localStorage.removeItem('timecardData');
          setLoading1(true);
          const getTimecardData = async () => {
              try {
                const token = await getTokenSilently();
                const response = await fetch(appPaths.apiPath + "/api/clockInOut", {
                  method: 'POST',
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',  // sent request
                    'Accept':       'application/json'   // expected data sent back
                  },
                  body: JSON.stringify(timecardData)
                });
                const responseData = await response.json();
                if(responseData.message)
                {
                  setInfoLoaded(false);
                  //setJobDetails(responseData.data.response.data[0].fieldData);
                  //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
                }
                else
                {
                  setInfoLoaded(false);
                  //alert(responseData);
                }
              } catch (error) {
                console.error(error);
              }
            };
            getTimecardData();
          
        }
      }

    }

    /*const createTimecard = (data) => {
      setNewLoading(true);
      localStorage.removeItem('timecardData');
      setLoading1(true);
      const createTimecard1 = async (data) => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/createTimecard", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify(data)
          });
          
          const responseData = await response.json();
          if(responseData.message)
          {
            setInfoLoaded(false);
            setNewLoading(false);
            //setJobDetails(responseData.data.response.data[0].fieldData);
            //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
          }
          else
          {
            //alert(responseData);
          }
        } catch (error) {
          console.error(error);
        }
      };
      createTimecard1(data);
    };*/

    useEffect(() => {
        if(!infoLoaded)
        {
          if(localStorage.getItem('timecardData'))
          {
            setInfoLoaded(true);
            setLoading1(false);
            setTimecardData(JSON.parse(localStorage.getItem('timecardData')));
            if(JSON.parse(localStorage.getItem('timecardData')).fieldData.TimeEnd_t != "")
            {
              setClockedIn(false)
            }
            else
            {
              setClockedIn(true);
            }
          }
            const getTimecardData = async () => {
                try {
                  const token = await getTokenSilently();
                  const response = await fetch(appPaths.apiPath + "/api/getTimecardData", {
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json',  // sent request
                      'Accept':       'application/json'   // expected data sent back
                    },
                    body: null
                  });
                  
                  const responseData = await response.json();
                  if(responseData.message)
                  {
                    if(responseData.data.fieldData.TimeEnd_t != "")
                    {
                      setClockedIn(false)
                    }
                    else
                    {
                      setClockedIn(true);
                    }
                    setInfoLoaded(true);
                    setLoading1(false);
                    localStorage.setItem('timecardData', JSON.stringify(responseData.data));
                    setTimecardData(responseData.data);
                    //setJobDetails(responseData.data.response.data[0].fieldData);
                    //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
                  }
                  else
                  {
                    setClockedIn(false);
                    localStorage.removeItem('timecardData');
                    setInfoLoaded(true);
                    setTimecardData(null);
                    setLoading1(false);
                    //alert(responseData);
                  }
                } catch (error) {
                  console.error(error);
                }
              };
              getTimecardData();
        }
    }, [getTokenSilently, infoLoaded])

    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        {!newLoading &&
        <>
          <Card className={classes.card} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
              Track Time For This Job
              </Typography>
            </CardContent>
            <ThemeProvider theme={theme2}>
              <Fab disabled={ loading1 ? true : timecardData !== null ? ((timecardData.fieldData._idf_job !== props.jobId && timecardData.fieldData.TimeEnd_t == "") ? true : false) : false } onClick={() => { changeStatus() }} style={!clockedIn ? {background: '#3f51b5', color: 'white'} : {background: '#f44336', color: 'white'}} className={classes.fab}>
                {loading1 && 
                  <ThemeProvider theme={theme}>
                      <CircularProgress ></CircularProgress>
                  </ThemeProvider>
                }
                {!loading1 &&
                  <>
                    {!clockedIn ? "CLOCK IN" : "CLOCK OUT"}
                  </>
                }
              </Fab>
            </ThemeProvider>
            {(loading1 ? false : timecardData !== null ? ((timecardData.fieldData._idf_job !== props.jobId && timecardData.fieldData.TimeEnd_t == "") ? true : false) : false) && 
            <span><b>You are clocked into another job and must clock out before clocking in to this job!</b></span>}
          </Card>
        </>
        }
        {newLoading && <CircularProgress></CircularProgress>}
      </div>
    );
}