import React, {useState, useRef, useEffect} from 'react';
import { GoogleMap, LoadScript, useGoogleMap, Polygon } from '@react-google-maps/api';
import {CircularProgress, Card, List, ListItem, Badge, Divider, ListItemText, ListItemIcon, TextField, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import appPaths from '../../../applicationPaths.json';
import { useAuth0 } from "../../../react-auth0-wrapper";
import RoomIcon from '@material-ui/icons/Room';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import theme from '../../../themes/theme';
import AuthConfig from '../../../auth_config.json';

const useStyles = makeStyles(theme => ({
    textField: {
    }
}));

export default function InvoiceImages(props) {

    useEffect(() =>
    {
        
        if(props.mapData != [] && props.center != null)
        {
            var mapObject = {
                key: AuthConfig.staticMapKey,
                size: "400x400",
                zoom: props.zoom,
                center: [props.center.lat, props.center.lng],
                maptype: "satellite",
                markers: [],
                roofSections: []
            }
            //console.log(props.zoom);
            for (var key in props.mapData) {
                var feature = props.mapData[key];
                if(props.features.filter(image => image.getProperty('id') == key).length > 0)
                {
                    if(feature.type == "Point")
                    {
                        //"color:red|label:S|40.714,-73.998","color:red|label:S|40.7,-73.97"
                        var newMarker = "color:red|label:" + feature.label[0];
                        feature.coords.forEach((latlng) => {
                            newMarker += "|" + latlng.lat() + "," + latlng.lng();
                        });
                        mapObject.markers.push(newMarker);
                    }
                    else
                    {
                        var newPath = "color:0x00000000"  + "|fillcolor:0x" + feature.color.substring(1) + "|weight:5"
                        feature.coords.forEach((latlng) => {
                            newPath += "|" + latlng.lat() + "," + latlng.lng();
                        });
                        mapObject.roofSections.push(newPath);
                    }
                }

            }

            if(mapObject.markers.length > 0)
            {
                var newMarker = "";
                mapObject.markers.forEach((marker, i) => {
                    if(i > 0)
                    {
                        newMarker +=  "&markers=" + encodeURIComponent(marker);
                    }
                    else
                    {
                        newMarker = "markers=" + encodeURIComponent(marker);
                    }
        
                });
                mapObject.markers = newMarker;
            }
            if(mapObject.roofSections.length > 0)
            {
                var newMarker = "";
                mapObject.roofSections.forEach((marker, i) => {
                    if(i > 0)
                    {
                        newMarker +=  "&path=" + encodeURIComponent(marker);
                    }
                    else
                    {
                        newMarker = "path=" + encodeURIComponent(marker);
                    }
        
                });
                mapObject.roofSections = newMarker;
            }
            //var queryString = Object.keys(mapObject).map(key => key + '=' + mapObject[key]).join('&');
            var queryString = Object.keys(mapObject).map((key) => {
                if(key == "markers")
                {
                    return mapObject[key];
                }
                else if (key == "roofSections")
                {
                    return mapObject[key];
                }
                else
                {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(mapObject[key]);
                }
        
            }).join('&');
            //"&path=color:0x00000000|fillcolor:0xFF000088|weight:5|40.737102,-73.990318|40.74,-73.98|40.752946,-73.987384"
            props.setUrl("https://maps.googleapis.com/maps/api/staticmap?" + queryString);
            //console.log(queryString);
        }
            }, [props.mapData, props.center, props.selectedImages]);


    const classes = useStyles();

    function handleTextFieldChange(event)
    {
        props.setDescription(event.target.value);
        
    }

    const generateImage = () => {
        props.mapRef.data.forEach(feature => {
            console.log(feature.getProperty('id'));
        });
    }
    const createInvoice = () => {
        props.createInvoice(props.url);
    }


    return (
        <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', flexDirection:'column', borderRadius: '0px'}}>
            <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px', paddingBottom: '20px'}}>
                <div style={{padding: '10px'}}>
                <TextField value={props.description} onChange={handleTextFieldChange} label="Description of Work Performed" multiline fullWidth className={classes.textField} placeholder="Description of Work Performed">

                </TextField>
                </div>
                <div id="mapImage">
                    {props.tab == 3 &&
                        <img src={props.url} style={{height:'400px'}} />
                    }
                    
                </div>
                <div>
                    <Button onClick={createInvoice} variant="contained" color="secondary">
                        Create Invoice
                    </Button>
                </div>
            </Card>

        </div>
    )
}