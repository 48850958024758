import React from "react";
import NavBar from "./components/NavBars/Navbar";

// New - import the React Router components, and the Profile page component
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Profile from "./components/Profile";
import PrivateRoute from "./components/PrivateRoute";
import ExternalApi from "./components/ExternalApi";
import Home from "./components/Home/Home";
import AlertDetails from "./components/AlertDetails";
import ReportLeak from "./components/Alert/ContactDuraroof";
import Job from "./components/Job";
import JobsList from "./components/JobsList";
import { useAuth0 } from "./react-auth0-wrapper";
import BottomNavBar from "./components/NavBars/bottomNavbar";
import Invoice from "./components/Invoice";
import JobMap from "./components/JobMap";
import JobCamera from './components/JobCamera';
import JobImages from "./components/Map/JobImageList";
import JobUpload from "./components/JobUpload";
import JobInvoice from './components/Invoice/Invoice';
import JobEditInvoice from './components/Invoice/editInvoice/Invoice';
import example from './components/ReactTable'
import AdminPanel from './components/Admin/adminPanel';
import AdminRoute from './components/Admin/AdminRoute';
import Timecard from "./components/Timecard/Timecard";
import AlertList from "./components/AlertList";
import JobTechs from "./components/Job/JobTechs";
import ReportLeakStaff from './components/Alert/ReportLeak';
import Test from './components/test';
import CreateUser from './components/User/CreateUser';



function App() {
  const { loading, isAuthenticated } = useAuth0();


  if (loading) {
    return <BrowserRouter><NavBar /></BrowserRouter>;
  }
  else
  {
    if(isAuthenticated)
    {
      //connect();
    }
  }


  

  return (
    <>
      {/* New - use BrowserRouter to provide access to /profile */}
      <BrowserRouter>
          <NavBar />
        <Switch>
          <Route path="/example" exact component={example} />
          <Route path="/" exact component={Home} />
          <Route path="/report-leak" exact component={ReportLeak} />
          <Route path="/test" exact component={Test} />
          <PrivateRoute path="/profile" exact component={Profile} />
          <PrivateRoute path="/external-api" exact component={ExternalApi} />
          <AdminRoute path="/alert-details" exact component={AlertDetails}/>
          <PrivateRoute path="/job" exact component={Job}/>
          <PrivateRoute path="/jobs-list" exact component={JobsList}/>
          <PrivateRoute path="/create-invoice" exact component={Invoice}/>
          <PrivateRoute path="/job-map" exact component={JobMap}/>
          <PrivateRoute path="/job-camera" exact component={JobCamera}/>
          <PrivateRoute path="/job-images" exact component={JobImages}/>
          <PrivateRoute path="/job-upload" exact component={JobUpload}/>
          <PrivateRoute path="/job-invoice" exact component={JobInvoice}/>
          <PrivateRoute path="/job-edit-invoice" exact component={JobEditInvoice}/>
          <PrivateRoute path="/timecard" exact component={Timecard} />
          <AdminRoute path="/admin-panel" exact component={AdminPanel}/>
          <AdminRoute path="/alert-list" exact component={AlertList} />
          <AdminRoute path="/job-techs" exact component={JobTechs} />
          <AdminRoute path="/create-user" exact component={CreateUser} />
          <PrivateRoute path="/report-leak-staff" exact component={ReportLeakStaff} />
        </Switch>
        
          <BottomNavBar />
      </BrowserRouter>
    </>
  );
}

export default App;