import React, { Component, useState, useEffect } from 'react';
import { useAuth0 } from "../react-auth0-wrapper";
import appPaths from '../applicationPaths.json';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { IconButton, ListItemSecondaryAction } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`,
  },
  textField: {
    //marginLeft: theme.spacing(1),
    //marginRight: theme.spacing(1),
    width: '100%',
  },
}));

const alertDetailDiv = {textAlign: "center"};
const alertDetailHeader = {textAlign: "center", color: "#00a2ff"};

const JobDetails = (props) => {
  const classes = useStyles();
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [showResult, setShowResult] = useState(false);
  const [jobDetails, setJobDetails] = useState([]);
  const [notes, setNotes] = useState('');
  const { getTokenSilently } = useAuth0();
  const [update, setUpdate] = useState(false);
  const [address_Street1, setAddress_Street1] = useState('');
  const [address_Street2, setAddress_Street2] = useState('');
  const [address_State, setAddress_State] = useState('');
  const [address_City, setAddress_City] = useState('');
  const [address_Zip, setAddress_Zip] = useState('');
  const [addressEdit, setAddressEdit] = useState(false);
  const [jobNameEdit, setJobNameEdit] = useState(false);
  const [jobName, setJobName] = useState("");


  function handleNoteChange(name) {
    setNotes(name.target.value);
  };


  useEffect(() => {
    const callApi = async () => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(appPaths.apiPath + "/api/updateJobDetails", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
          body: JSON.stringify({data: jobDetails})
        });
  
        const responseData = await response.json();
        console.log(responseData);
        if(responseData.message)
        {
          setUpdate(false);
          setShowResult(false);
          
          //setJobDetails(responseData.data.response.data[0].fieldData);
          //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
        }
        else
        {
          //alert(responseData);
        }

      } catch (error) {
        console.error(error);
        //setShowResult(false);
      }
    };
    if(update)
    {
      callApi();
    }
  }, [jobDetails, update, getTokenSilently]);

  useEffect(() => {

    if(!showResult)
    {
      
      const callApi = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/getJobDetails", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({id: props.__ID})
          });
    
          const responseData = await response.json();
          if(responseData.message)
          {
            setJobDetails(responseData.data.response.data[0].fieldData);
            setShowResult(true);
            setJobName(responseData.data.response.data[0].fieldData.Job_Name);
            setNotes(responseData.data.response.data[0].fieldData.TechNotes);
            setAddress_Street1(responseData.data.response.data[0].fieldData.address_Street1_Ae);
            setAddress_Street2(responseData.data.response.data[0].fieldData.address_Street2_Ae);
            setAddress_State(responseData.data.response.data[0].fieldData.address_State_Ae);
            setAddress_Zip(responseData.data.response.data[0].fieldData.address_Zip_Ae);
            setAddress_City(responseData.data.response.data[0].fieldData.address_City_Ae);
          }
          else
          {
            //alert(responseData);
          }

        } catch (error) {
          console.error(error);
          setShowResult(false);
        }
      };
        callApi();
    }
},[getTokenSilently, props.__ID, showResult]);

function handleJobNameChange(value)
{
  setJobName(value.target.value);
}

function handleStreet1Change(value)
{
  setAddress_Street1(value.target.value);
}
function handleStreet2Change(value)
{
  setAddress_Street2(value.target.value);
}
function handleStateChange(value)
{
  setAddress_State(value.target.value);
}
function handleZipChange(value)
{
  setAddress_Zip(value.target.value);
}
function handleCityChange(value)
{
  setAddress_City(value.target.value);
}

  return (
    <>
      {showResult && <>
        <ListItem>
        {!jobNameEdit &&
        <>
          <ListItemText style={{color: 'black'}} primary="Job Name" secondary={jobDetails.Job_Name} />
          {props.admin &&
          <ListItemIcon>
              <IconButton onClick={() => setJobNameEdit(true)}>
                <EditIcon />
              </IconButton>
          </ListItemIcon>
          }
          </>
        }
        {jobNameEdit &&
          <>
            {/*<ListItemText style={{color: 'black'}} primary="Address" />*/}
            <ListItemText>
            <TextField
              style={{width: '100%'}}
              multiline
              id="jobName"
              label="Job Name"
              value={jobName}
              onChange={handleJobNameChange}
              className={classes.textField}
              margin="normal"
            />
            </ListItemText>
            <ListItemIcon>
              <IconButton onClick={() => setJobNameEdit(false)}>
                <CloseIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemIcon>
              <IconButton onClick={() => { setJobNameEdit(false); setUpdate(true); setJobDetails(prevState => { console.log({...prevState, Job_Name: jobName}); return {...prevState, Job_Name: jobName}});}} >
                <DoneIcon />
              </IconButton>
            </ListItemIcon>
            </>
        }
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText style={{color: 'black'}} primary="Job Number" secondary={jobDetails.Number_JobCombined} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText style={{color: 'black'}} primary="Job Description" secondary={jobDetails.Job_Description} />
        </ListItem>
        <Divider />
        {/*
        <ListItem>
          
          <Typography
            className={classes.dividerFullWidth}
            color="textSecondary"
            display="block"
            variant="caption"
          >
            Customer
          </Typography>
          
          </ListItem>
          */}
        <ListItem>
          <ListItemText style={{color: 'black'}} primary="Customer"  secondary={jobDetails.Customer_lu} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          {/*
          <Typography
            className={classes.dividerFullWidth}
            color="textSecondary"
            display="block"
            variant="caption"
            >
            Address
          </Typography>
          */}
          {!addressEdit &&
          <>
            <ListItemText style={{color: 'black'}} primary="Address" secondary={
              (jobDetails.address_Street1_Ae != '' || jobDetails.address_City_Ae != '' || jobDetails.address_State_Ae != '' || jobDetails.address_Zip_Ae != '') &&
            <>
                  <span>{jobDetails.address_Street1_Ae}<br></br>{jobDetails.address_Street2_Ae != '' && (<>{jobDetails.address_Street2_Ae}<br></br></>)}{jobDetails.address_City_Ae} {jobDetails.address_State_Ae}, {jobDetails.address_Zip_Ae}</span>
            </>
            } />
            <ListItemIcon>
              <IconButton onClick={() => setAddressEdit(true)}>
                <EditIcon />
              </IconButton>
            </ListItemIcon>
          </>
          }
          {addressEdit &&
          <>
            {/*<ListItemText style={{color: 'black'}} primary="Address" />*/}
            <ListItemText>
            <TextField
              style={{width: '100%'}}
              multiline
              id="street1"
              label="Street Address 1"
              value={address_Street1}
              onChange={handleStreet1Change}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              style={{width: '100%'}}
              multiline
              id="street2"
              label="Street Address 2"
              value={address_Street2}
              onChange={handleStreet2Change}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              style={{width: '33.3%'}}
              multiline
              id="city"
              label="City"
              value={address_City}
              onChange={handleCityChange}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              style={{width: '33.4%'}}
              multiline
              id="state"
              label="State"
              value={address_State}
              onChange={handleStateChange}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              style={{width: '33.3%'}}
              multiline
              id="zip"
              label="Zip"
              value={address_Zip}
              onChange={handleZipChange}
              className={classes.textField}
              margin="normal"
            />
            </ListItemText>
            <ListItemIcon>
              <IconButton onClick={() => setAddressEdit(false)}>
                <CloseIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemIcon>
              <IconButton onClick={() => { setAddressEdit(false); setUpdate(true); setJobDetails(prevState => { return {...prevState, address_Street1_Ae: address_Street1, address_Street2_Ae: address_Street2, address_State_Ae: address_State, address_City_Ae: address_City, address_Zip_Ae: address_Zip}});}} >
                <DoneIcon />
              </IconButton>
            </ListItemIcon>
          </>
          }
        </ListItem>
        
        <Divider />
        
        <ListItem>
        
          <TextField
            multiline
            id="standard-name"
            label="Tech Notes"
            value={notes}
            onChange={handleNoteChange}
            className={classes.textField}
            margin="normal"
          />
        </ListItem>
        <Divider />
      {notes != jobDetails.TechNotes && 
        <ListItem style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button onClick={() => {setNotes(jobDetails.TechNotes); }} variant="contained" color="primary">
            Cancel Changes
          </Button>
          <Button onClick={() => {setUpdate(true); setJobDetails(prevState => { return {...prevState, TechNotes: notes}}); }} variant="contained" color="primary">
            Save Changes
          </Button>
          
        </ListItem>}
      </>}
      {!showResult && <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} className={classes.img}><CircularProgress></CircularProgress></div>}
    </>
  );
};

export default JobDetails;