import React, { useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-wrapper";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CircularProgress, TextField } from '@material-ui/core';
import appPaths from '../../applicationPaths.json';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles(theme => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
  }));

function Details(props)
{
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [workType, setWorkType] = useState('');
    const [jobType, setJobType] = useState('');
    const [jobs, setJobs] = useState([]);
    const [jobsLoaded, setJobsLoaded] = useState(false);
    const { getTokenSilently } = useAuth0();
    const [changeOrderTM, setChangeOrderTM] = useState('');
    const [changeOrder, setChangeOrder] = useState('');
    const [changeOrders, setChangeOrders] = useState([]);
    const [customTask, setCustomTask] = useState("");

    function compare(a, b) {
        if (a.fieldData.Job_Name < b.fieldData.Job_Name) {
          return -1;
        }
        if (a.fieldData.Job_Name > b.fieldData.Job_Name) {
          return 1;
        }
        return 0;
      }

    useEffect(() => {
        if(!jobsLoaded)
        {
            const getJobs = async () => {
                try {
                    const token = await getTokenSilently();
                    const response = await fetch(appPaths.apiPath + "/api/getJobsList", {
                        method: 'GET',
                        headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',  // sent request
                        'Accept':       'application/json'   // expected data sent back
                        },
                        body: null
                    });
                    
                    const responseData = await response.json();
                    if(responseData.message)
                    {
                        const newJobs = responseData.data.response.data.sort(compare);
                        setJobs(newJobs);
                        setJobsLoaded(true);

                    //setJobDetails(responseData.data.response.data[0].fieldData);
                    //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
                    }
                    else
                    {
                        setJobsLoaded(true);
                        //alert(responseData);
                    }
          
                } catch (error) {
                  console.error(error);
                }
              };
              getJobs();
        }
    }, [getTokenSilently, jobsLoaded])

    useEffect(() => {
        if(jobType != '')
        {

            console.log(jobType)
            const getJobs = async () => {
                try {
                    const token = await getTokenSilently();
                    const response = await fetch(appPaths.apiPath + "/api/getChangeOrders", {
                        method: 'POST',
                        headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',  // sent request
                        'Accept':       'application/json'   // expected data sent back
                        },
                        body: JSON.stringify({id: jobType})
                    });
                    
                    const responseData = await response.json();
                    if(responseData.message)
                    {
                        
                        setChangeOrders(responseData.data.response.data);

                    }
                    else
                    {

                    }
            
                } catch (error) {
                    console.error(error);
                }
                };
                getJobs();
        }
    }, [getTokenSilently, jobType])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = event => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = event => {
        setFullWidth(event.target.checked);
    };

    const saveClose = () => {
        let data = {}
        data._idf_job = jobType;
        data._idf_changeOrder = changeOrder;
        data.WorkType = workType;
        data.flag_ChangeOrder = changeOrderTM;
        data.SystemTask_Lu = customTask;
        setOpen(false);
        props.createTimecard(data);
    };

    return (
        <>
            <Button variant="contained" color="secondary" onClick={handleClickOpen}>
                Change Job
            </Button>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                {jobsLoaded && <>
                <DialogTitle id="max-width-dialog-title">Timecard</DialogTitle>
                <DialogContent>
                <form className={classes.form} noValidate>
                
                    <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="workType">Work Type</InputLabel>
                    <Select
                        value={workType}
                        onChange={(event) => { setWorkType(event.target.value) }}
                        inputProps={{
                            name: 'workType',
                            id: 'workType',
                        }}
                    >
                        <MenuItem value="RFG Field">RFG Field</MenuItem>
                        <MenuItem value="RFG Delivery">RFG Delivery</MenuItem>
                        <MenuItem value="RFG PM">RFG PM</MenuItem>
                        <MenuItem value="SM Field">SM Field</MenuItem>
                        <MenuItem value="SM Shop">SM Shop</MenuItem>
                        <MenuItem value="SM Delivery">SM Delivery</MenuItem>
                        <MenuItem value="SM PM">SM PM</MenuItem>
                    </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="jobType">Job</InputLabel>
                    <Select
                        value={jobType}
                        onChange={(event) => { setChangeOrders([]); setJobType(event.target.value) }}
                        inputProps={{
                            name: 'jobType',
                            id: 'jobType',
                        }}
                    >
                        {jobs.map(job => {
                            return <MenuItem key={job.fieldData.__ID} value={job.fieldData.__ID}>{job.fieldData.Job_Name}</MenuItem>
                        })}
                    </Select>
                    </FormControl>
                    {jobType !== "" && workType !== "" &&
                    <>
                        <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="changeOrder">Change Order</InputLabel>
                        <Select
                            value={changeOrder}
                            onChange={(event) => { setChangeOrder(event.target.value) }}
                            inputProps={{
                            name: 'changeOrder',
                            id: 'changeOrder',
                            }}
                        >
                            {changeOrders.map(changeOrder => {
                                return <MenuItem key={changeOrder.fieldData.__ID} value={changeOrder.fieldData.__ID}>{changeOrder.fieldData.ChangeOrderNumber_Ae + " " + changeOrder.fieldData.EstimateName}</MenuItem>
                            })}
                        </Select>
                        </FormControl>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">{"Change Order T&M"}</FormLabel>
                            <RadioGroup aria-label="changeOrder" name="changeOrder" value={changeOrderTM} onChange={(event) => { setChangeOrderTM(event.target.value) }}>
                                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                <FormControlLabel value="" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                        <TextField
                            value={customTask}
                            label="Enter Custom Task"
                            onChange={(event) => { setCustomTask(event.target.value) }}
                        >

                        </TextField>
                        </FormControl>
                    </>
                    }
                </form>
                </DialogContent>
                <DialogActions style={{ display:'flex', justifyContent: "space-between",}}>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button color="secondary" onClick={saveClose}>
                    Save
                </Button>
                </DialogActions>
                </>}
                {!jobsLoaded && <CircularProgress></CircularProgress>}
            </Dialog>
        </>
    );
}

export default Details;