import React, { Component, useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useAuth0 } from "../react-auth0-wrapper";
import appPaths from '../applicationPaths.json';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageIcon from '@material-ui/icons/Image';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}));

export default function JobImages(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [stepImages, setStepImages] = useState([]);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { getTokenSilently } = useAuth0();
  const [beforeAfter, setBeforeAfter] = useState(true);

  useEffect(() => {
    const callApi = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/getJobImages", {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',  // sent request
              'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({id: props.__ID})
          });
    
          const responseData = await response.json();
          if(responseData.message)
          {
            setStepImages(responseData.data.response.data);
            setLoading(false);
            
            //setJobDetails(responseData.data.response.data[0].fieldData);
            //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
          }
          else
          {
              setLoading(false);
            //alert(responseData);
          }
  
        } catch (error) {
          console.error(error);
        }
      };

    callApi();


    }, [props.update, getTokenSilently, props.__ID]);


  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  return (
      
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
    <div className={classes.root}>
        
        {!loading && stepImages.length > 0 && (
            <>

                <MobileStepper
                steps={stepImages.length}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === stepImages.length - 1}>
                    Next
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
                }
                backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    Back
                </Button>
                }
                />
                
                <Collapse onClick={() => {if(beforeAfter){ setBeforeAfter(false); }}} collapsedHeight="0px" in={!beforeAfter}> {stepImages[activeStep].fieldData.PictureAfter_oAe != '' &&
                <>
                {props.editable && 
                <div style={{position: 'absolute', width: '100%', flexDirection: 'row-reverse', display: 'flex'}}>
                  
                  <IconButton style={{backgroundColor: 'white'}} onClick={() => {props.addImage(stepImages[activeStep].fieldData.__ID, beforeAfter)}} color="primary">
                  <EditIcon />
                </IconButton></div>
                }
                <img
                style={{width: '100%'}}
                src={stepImages[activeStep].fieldData.PictureAfter_oAe}
                alt={stepImages[activeStep].fieldData.DescriptionAfter}
                />
                </>
                }
                {stepImages[activeStep].fieldData.PictureAfter_oAe == '' && 
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} className={classes.img}>
                </div>
                }
                <Paper elevation={0} className={classes.header}>
                <Typography>{stepImages[activeStep].fieldData.DescriptionAfter}</Typography>
                </Paper>
                </Collapse>
                
                <Button variant="contained" color="primary" onClick={() => {if(beforeAfter){ setBeforeAfter(false); } else { setBeforeAfter(true); }}} style={{textAlign: 'center', width: '100%', borderRadius: '0'}} elevation={0}>
                <Typography>{beforeAfter && <>Before</>}{!beforeAfter && <>After</>}</Typography>
                </Button>
                <Collapse onClick={() => {if(!beforeAfter){ setBeforeAfter(true); }}} collapsedHeight="0px" in={beforeAfter}> {stepImages[activeStep].fieldData.Picture_oAe != '' &&
                <>
                {props.editable && 
                <div style={{position: 'absolute', width: '100%', flexDirection: 'row-reverse', display: 'flex'}}><IconButton style={{backgroundColor: 'white'}} onClick={() => {props.addImage(stepImages[activeStep].fieldData.__ID, beforeAfter)}} color="primary">
                  <EditIcon />
                </IconButton></div>
                }
                
                <img
                style={{width: '100%'}}
                src={stepImages[activeStep].fieldData.Picture_oAe}
                alt={stepImages[activeStep].fieldData.Description}
                />
                </>
              }
              {stepImages[activeStep].fieldData.Picture_oAe == '' && 
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} className={classes.img}>

                </div>
                }
                <Paper elevation={0} className={classes.header}>
                <Typography>{stepImages[activeStep].fieldData.Description}</Typography>
                </Paper>
                </Collapse>
                <IconButton onClick={() => {props.addImage(stepImages[activeStep])}} color="primary">
                    <AddIcon fontSize="large" />
                  </IconButton>
                
                
                
            </>
  )}
  {loading && (
    <>
        <Paper elevation={0} className={classes.header}>
        <Typography></Typography>
        </Paper>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} className={classes.img}>
            <CircularProgress  />
        </div>
        
        
    </>
  )}
  {!loading && stepImages.length == 0 && (
            <>
                <Paper elevation={0} className={classes.header}>
                <Typography>No Images to Display</Typography>
                </Paper>
                
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'black'}} className={classes.img}>
                    <ImageIcon fontSize="large"/>
                </div>
                
            </>
  )}
      
    </div>
    </div>
  );
}