import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { Switch, Checkbox, FormControlLabel, Button, CircularProgress } from '@material-ui/core';
import appPaths from '../../applicationPaths.json';
import { useAuth0 } from "../../react-auth0-wrapper";
import { Redirect } from 'react-router';

export default function CreateUser(props)
{
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [admin, setAdmin] = useState(false);
    const [loading, setLoading] = useState(false);
    const { getTokenSilently } = useAuth0();
    const [redirect, setRedirect] = useState({set: false, to: {pathname: "/", state: {}}})


    const formValid = async (callback) => {
        if(email !== "" && name !== "" && password !== "")
        {
          return true;
        }
        else
        {
          return false;
        }
      }

    const createUser = async () => {
        const result = await formValid();
          if(result)
          {
          setLoading(true);
          try {
            const token = await getTokenSilently();
            const response = await fetch(appPaths.apiPath + "/api/createAuthUser", {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
              },
              body: JSON.stringify({
                email: email,
                name: name,
                password: password,
                role: admin ? "admin" : "tech"
              })
            });
        
            const responseData = await response.json();
            setLoading(false);
            if(responseData.message)
            {
              alert("User Succesfully Created!")
              let data = {set: true, to: {pathname: "/", state: {}}};
              setRedirect(data);
  
            }
            else
            {
                alert("User Creation Failed!");
            }
        
          } catch (error) {
            console.error(error);
          }
        }
        else
        {
            alert("You must fill out all of the fields");
        }
    };



    return (
        <div style={{width:'100%', display:'flex', justifyContent:'center', flexDirection:'column', textAlign:'center'}}>
            {redirect.set && 
                (<Redirect push to={redirect.to} />
            )}
            {!loading &&
            <Paper style={{display: 'flex', justifyContent: 'center', flexDirection:'column', padding:'20px', margin: '20px'}}>
                <FormControl>
                    <InputLabel>Email</InputLabel>
                    <Input required type="text" placeholder="Email" name="email" value={email} onChange={(event => { setEmail(event.target.value) })}/>
                </FormControl>
                <FormControl>
                    <InputLabel>Name</InputLabel>
                    <Input required type="text" placeholder="Name" name="name" value={name} onChange={(event => { setName(event.target.value) })}/>
                </FormControl>
                <FormControl>
                    <InputLabel>Password</InputLabel>
                    <Input required type="password" placeholder="Password" name="password" value={password} onChange={(event => { setPassword(event.target.value) })}/>
                </FormControl>
                <br></br>
                <FormControl>
                <FormControlLabel control={
                <Checkbox
                  checked={admin}
                  onChange={() => {setAdmin(!admin)}}
                  value="admin"
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />
              } label="Admin?" />
              </FormControl>
              <br></br>
              <Button variant="contained" color="primary" onClick={() => { createUser() }}>
                Create User
              </Button>
                    
            </Paper>
            }
            {loading && <CircularProgress />}
        </div>
    );
}