import React, { Component, useState, useEffect } from 'react';
import { useAuth0 } from "../react-auth0-wrapper";
import Button from '@material-ui/core/Button';
import styles from './home.module.css';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import appPaths from '../applicationPaths.json';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import JobDetails from './JobDetails.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import ReplayIcon from '@material-ui/icons/Replay';
import Divider from '@material-ui/core/Divider';
import JobImages from './JobImages';
import InspectionsList from './InspectionsList';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import JobTechs from './Job/JobTechs';
import { Redirect } from 'react-router';
import Timecard from "./Job/Timecard";
import InvoiceList from './Job/InvoiceList';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const alertDetailDiv = {textAlign: "center"};
const alertDetailHeader = {textAlign: "center", color: "#00a2ff"};


const listStyles = makeStyles(theme => ({
  root: {
    minWidth: 250,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`,
  },
}));


const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      margin: '20px',
      marginTop: '0px',
    },
    table: {
    },
  }));


const Job = (props) => {
    const classes = useStyles();
    const list = listStyles();
    const { isAuthenticated, loginWithRedirect, logout, loading } = useAuth0();
    const [images, setImages] = useState([]);
    const [mirror, setMirror] = useState(false);
    const [idealFacingMode, setIdealFacingMode] = useState('environment');
    const [showImages, setShowImages] = useState(0);
    const [caption, setCaption] = useState('');
    const { getTokenSilently } = useAuth0();
    const [uploading, setUploading] = useState(false);
    const [open, setOpen] = useState(false);
    const [redirect, setRedirect] = useState({set: false, to: {pathname: "/create-invoice", state: {}}});
    const [newImages, setNewImages] = useState(0);
    const [values, setValues] = React.useState({
      beforeAfter: 'Before',
      id:''
    });
    const removeImage = (i) => {
      let currentImages = images;
      currentImages.splice(i, 1);
      setImages(currentImages);
      let number = showImages;
      setShowImages(number - 1);
      
    };

    const [userPermissions, setUserPermissions] = useState([]);

    useEffect(() => {
      const getPermissions = async () => {
        try {
          const token = await getTokenSilently();
          const response = await fetch(appPaths.apiPath + "/api/getUserPermissions", {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const responseData = await response.json();
          console.log(responseData);
          setUserPermissions(responseData);
        }
        catch (error) {
          console.error(error);
        }
      };
      if(!loading)
      {
        getPermissions();
      }
  
    },[getTokenSilently, loading]);

    const uploadImages = () => {
      setUploading(true);
      images.forEach((image, i) => {
        var formData = new FormData();
        formData.append('upload', images[i].data);
        formData.append('_idf_job', props.location.state.__ID);
        formData.append('imageDescription', caption);
        formData.append('beforeAfter', values.beforeAfter);
        formData.append('__ID', values.__ID);
        uploadImage(formData, 0);
      })
    };


    const uploadImage = async (form, i) => {
      try {
        const token = await getTokenSilently();
    
        const response = await fetch(appPaths.apiPath + "/api/uploadImages", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json'   // expected data sent back
          },
          body: form
        });
    
        const responseData = await response.json();
        if(responseData.data.message || i > 9)
        {
          if(i > 9)
          {
            if(window.confirm("Image failed to upload! Would you like to try resending it?"))
            {
              uploadImage(form, 0);
            }
          }
          else
          {
            setNewImages(newImages + 1);
            setImages([]);
            setCaption('');
            handleClose();
          }
          setUploading(false);
          
        }
        else
        {
          uploadImage(form, i + 1)
        }
        
    
      } catch (error) {
        console.error(error);
        setUploading(false);
      }
    };

    const onTakePhoto = (dataUri) => {
        let currentImages = images;
        currentImages.unshift({data: dataUri, description: ''});
        setImages(currentImages);
        let number = showImages;
        // Do stuff with the dataUri photo...
      };
      function handleTextFieldChange(textValue)
      {
        setCaption(textValue.target.value);
      }
      function handleClose(type) {
        setValues({__ID:'',beforeAfter:'Before'});
        setOpen(false);
        
      }
      function handleChange(event) {
        setValues(oldValues => ({
          ...oldValues,
          [event.target.name]: event.target.value,
        }));
      }
      function addImage(__ID, beforeAfter)
      {
        if(beforeAfter)
        {
          setValues({__ID:__ID,beforeAfter:'Before'});
          setOpen(true);
        }
        else
        {
          setValues({__ID:__ID,beforeAfter:'After'});
          setOpen(true);
        }
        
      }
      

    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
      {redirect.set && 
        (<Redirect push to={redirect.to} />
      )}
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
        <List className={list.root}>
        <JobDetails admin={userPermissions.includes("type:admin")} __ID={props.location.state.__ID}/>
        <Divider component="li" />
        {/*
        <JobImages __ID={props.location.state.__ID} update={newImages} addImage={addImage} editable={true} />


        
      <Divider component="li" />
      <ListItem style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Button variant="contained" color="primary"  onClick={() => {setValues({__ID:'',beforeAfter: 'Before'}); setOpen(true)}}>Add New Picture</Button>
      </ListItem>
        */}
        {!open && <>
          <ListItem style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Button variant="contained" color="primary"  onClick={() => {setRedirect({set: true, to: {pathname: "/job-map", state: {__ID: props.location.state.__ID}}});}}>
              View Job on Map and Make Edits
            </Button>
          </ListItem>

          <ListItem style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Button variant="contained" color="primary"  onClick={() => {setRedirect({set: true, to: {pathname: "/job-invoice", state: {__ID: props.location.state.__ID}}});}}>
              Add Materials and Create Invoice
            </Button>
          </ListItem>
          <ListItem style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          {userPermissions.includes("type:admin") &&
            <InvoiceList setRedirect={setRedirect} __ID={props.location.state.__ID} />
          }
          </ListItem>
          <ListItem style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width:'100%'}}>
            {/*<Button disabled={userPermissions.includes("type:admin") ? false : true } variant="contained" color="primary"  onClick={() => {setRedirect({set: true, to: {pathname: "/job-techs", state: {__ID: props.location.state.__ID}}});}}>
              View Tech's
        </Button>*/}
        {userPermissions.includes("type:admin") &&
            <JobTechs location={{state:{__ID:props.location.state.__ID}}} />
        }
          </ListItem>
          <ListItem style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width:'100%'}}>
            <Timecard jobId={props.location.state.__ID} />
          </ListItem>
        
        </>}
        {/* 
        <Divider component="li" />
        <ListItem style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Button variant="contained" color="primary"  onClick={() => {}}>Create Inspection</Button></ListItem>
        <Divider component="li" />
        <InspectionsList __ID={props.location.state.__ID} />
        */}
        </List>
        {/* 
        <Dialog style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => { handleClose(false) }} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Upload Image
              </Typography>
            </Toolbar>
          </AppBar>
          <Toolbar style={{paddingBottom: '20px'}}/>
          <div style={{width:'100vw'}}></div>
          {uploading && <><CircularProgress className={classes.progress} /></>}
          {!uploading &&
          <>
            <Paper style={{maxWidth: '90vw'}} className={classes.root}>
            {images.length == 0 && <div style={{position: 'relative'}}>
            <Camera
                imageType="jpg"
                idealFacingMode = {idealFacingMode}
                isImageMirror={mirror}
                onTakePhoto = { (dataUri) => { onTakePhoto(dataUri); } }
            /><Button onClick={(event) => {if(idealFacingMode == 'environment') {setIdealFacingMode('user'); setMirror(true);} else { setIdealFacingMode('environment'); setMirror(false); }}} variant="contained" style={{position: 'absolute', right: '0', top: '0'}}><ReplayIcon /></Button></div>}
            
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            {images.length > 0 && images.map((row, i) => (
                <div key={i} style={{position: 'relative'}}><img style={{width: '100%'}} src={row.data}>
                </img><Button onClick={(event) => {removeImage(i); setCaption('')}} variant="contained" style={{backgroundColor:"#FF0000", position: 'absolute', right: '0', top: '0'}}>X</Button></div>
            ))}
            {images.length > 0 &&
            <>
              <TextField value={caption} onChange={handleTextFieldChange} multiline style={{ margin: 8 }} fullWidth className={classes.textField} variant="outlined" placeholder="Caption"></TextField>
              {values.__ID == '' &&
                <Select
                  onChange={handleChange}
                  value={values.beforeAfter}
                  style={{width: '100%'}}
                  input={<OutlinedInput name="beforeAfter" id="beforeAfter" />}
                >
                  <MenuItem value='Before'>Before</MenuItem>
                  <MenuItem value='After'>After</MenuItem>
                </Select>
              }
              </>
            }
            </div>
          </Paper>
          {images.length > 0 &&
          <>
          <Button variant="contained" color="primary" onClick={() => {uploadImages()}}>Upload Image</Button>
          </>
        }
        
        </>}
 
            </Dialog>
        
        */}
        
        
        

    </div>
      </div>
    );

}
export default Job;