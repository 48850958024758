import React, { Component, useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useAuth0 } from "../react-auth0-wrapper";
import appPaths from '../applicationPaths.json';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageIcon from '@material-ui/icons/Image';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}));

export default function JobImages(props) {
  console.log(props);
  const classes = useStyles();
  const theme = useTheme();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { getTokenSilently } = useAuth0();
  const [beforeAfter, setBeforeAfter] = useState(true);

  return (
      
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
    <div className={classes.root}>
        
        {props.images.length > 0 && (
            <>

                <MobileStepper
                steps={props.images.length}
                position="static"
                variant="text"
                activeStep={props.activeStep}
                nextButton={
                <Button size="small" onClick={props.handleNext} disabled={props.activeStep === props.images.length - 1}>
                    Next
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
                }
                backButton={
                <Button size="small" onClick={props.handleBack} disabled={props.activeStep === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    Back
                </Button>
                }
                />
                
                <Collapse onClick={() => {if(beforeAfter){ setBeforeAfter(false); }}} collapsedHeight="0px" in={!beforeAfter}>
                  {console.log(props.activeStep)}
                  {console.log(props.images)}
                  
                {props.images[props.activeStep].fieldData.PictureAfter_oAe != '' &&
                <>
                <img
                style={{width: '100%'}}
                src={props.images[props.activeStep].fieldData.PictureAfter_oAe}
                alt={props.images[props.activeStep].fieldData.DescriptionAfter}
                />
                </>
                }
                {props.images[props.activeStep].fieldData.PictureAfter_oAe == '' && 
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} className={classes.img}>
                </div>
                }
                <Paper elevation={0} className={classes.header}>
                <Typography>{props.images[props.activeStep].fieldData.DescriptionAfter}</Typography>
                </Paper>
                </Collapse>
                
                <Button variant="contained" color="primary" onClick={() => {if(beforeAfter){ setBeforeAfter(false); } else { setBeforeAfter(true); }}} style={{textAlign: 'center', width: '100%', borderRadius: '0'}} elevation={0}>
                <Typography>{beforeAfter && <>Before</>}{!beforeAfter && <>After</>}</Typography>
                </Button>
                <Collapse onClick={() => {if(!beforeAfter){ setBeforeAfter(true); }}} collapsedHeight="0px" in={beforeAfter}> {props.images[props.activeStep].fieldData.Picture_oAe != '' &&
                <>
                {props.editable && 
                <div style={{position: 'absolute', width: '100%', flexDirection: 'row-reverse', display: 'flex'}}>
                  <IconButton style={{backgroundColor: 'white'}} onClick={() => {props.addImage(props.images[props.activeStep].fieldData.__ID, beforeAfter)}} color="primary">
                  <EditIcon />
                </IconButton></div>
                }
                
                <img
                style={{width: '100%'}}
                src={props.images[props.activeStep].fieldData.Picture_oAe}
                alt={props.images[props.activeStep].fieldData.Description}
                />
                </>
              }
              {props.images[props.activeStep].fieldData.Picture_oAe == '' && 
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} className={classes.img}>

                </div>
                }
                <Paper elevation={0} className={classes.header}>
                <Typography>{props.images[props.activeStep].fieldData.Description}</Typography>
                </Paper>
                </Collapse>
                <IconButton onClick={() => { props.removeImage(props.activeStep)}} color="primary">
                    <HighlightOffIcon fontSize="large" />
                  </IconButton>
                
                
                
            </>
  )}
  {props.images.length == 0 && (
            <>
                <Paper elevation={0} className={classes.header}>
                <Typography>No Images to Display</Typography>
                </Paper>
                
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'black'}} className={classes.img}>
                    <ImageIcon fontSize="large"/>
                </div>
                
            </>
  )}
      
    </div>
    </div>
  );
}