import React, { useState, useEffect } from 'react';
import styles from './contactDuraroof.module.css';
import logo from '../../assets/images/logo.png';
import Button from '@material-ui/core/Button';
import { FormGroup, FormControl, InputLabel, Input, CircularProgress, MenuItem, Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import appPaths from '../../applicationPaths.json';
import Zoom from '@material-ui/core/Zoom';
import { useAuth0 } from "../../react-auth0-wrapper";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Redirect } from 'react-router';
import PlacesAutosuggest from './PlacesAutosuggest';


export default function ReportLeak(props)
{
    const [description, setDescription] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [contactPersonFirstName, setContactPersonFirstName] = useState("");
    const [contactPersonLastName, setContactPersonLastName] = useState("");
    const [contactPersonPhone, setContactPersonPhone] = useState("");
    const [customer, setCustomer] = useState("");
    const [contactType, setContactType] = useState("");
    const [customers, setCustomers] = useState([]);
    const { getTokenSilently } = useAuth0();
    const [newCustomerChecked, setNewCustomerChecked] = useState(false);
    const [newJobsiteChecked, setNewJobsiteChecked] = useState(false);
    const [jobsiteStreetAddress, setJobsiteStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [building, setBuilding] = useState("");
    const [lockbox, setLockbox] = useState("");
    const [salesperson, setSalesperson] = useState("");
    const [salespeople, setSalespeople] = useState([]);
    const [ladderNeeded, setLadderNeeded] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("Alert Succesfully Created!");
    const [timetrackerSnackbarOpen, setTimetrackerSnackbarOpen] = useState(false);
    const [toAlertDetails, setToAlertDetails] = useState({set: false, location: "/"});
    const [loading, setLoading] = useState(false);
    const [customerJobsites, setCustomerJobsites] = useState([]);
    const [customerJobsite, setCustomerJobsite] = useState("");
    const [jobType, setJobType] = useState("");

    useEffect(() => {

          const callApi = async () => {
            try {
              const token = await getTokenSilently();
          
              const response = await fetch(appPaths.apiPath + "/api/getCustomers", {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',  // sent request
                  'Accept':       'application/json'   // expected data sent back
                }
              });
          
              const responseData = await response.json();

              if(responseData.message)
              {
                setCustomers(responseData.data.response.data);
              }
              else
              {

              }
          
            } catch (error) {
              console.error(error);
            }
          };
            callApi();
      
      }, [getTokenSilently]);

      useEffect(() => {

        const callApi = async () => {
          try {
            const token = await getTokenSilently();
        
            const response = await fetch(appPaths.apiPath + "/api/getSalespeople", {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
              }
            });
        
            const responseData = await response.json();
            if(responseData.message)
            {
              setSalespeople(responseData.data.response.data);
            }
            else
            {

            }
        
          } catch (error) {
            console.error(error);
          }
        };
          callApi();
    
    }, [getTokenSilently]);

    useEffect(() => {
      if(customer !== "")
      {
        const callApi = async () => {
          try {
            const token = await getTokenSilently();
        
            const response = await fetch(appPaths.apiPath + "/api/getJobsiteDetails", {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
              },
              body: JSON.stringify({id: customer.fieldData.__ID})
            });
        
            const responseData = await response.json();
            console.log(responseData);
            if(responseData.message)
            {
              setCustomerJobsites(responseData.data.response.data);
            }
            else
            {
              
            }
        
          } catch (error) {
            console.error(error);
          }
        };
        callApi();
      }
      
  
  }, [customer, getTokenSilently]);

    const formValid = async (callback) => {
      if(description == "")
      {
        return false;
      }
      else if(!newCustomerChecked && customer == "")
      {
        return false;
      }
      else if(newCustomerChecked && (companyName == "" || (companyPhone == "" && companyEmail == "")))
      {
        return false;
      }
      else if(jobsiteStreetAddress == "" || city == "" || zip == "" || state == "")
      {
        return false;
      }
      else if(newJobsiteChecked && !newCustomerChecked && building == "")
      {
        return false;
      }
      else if(!newJobsiteChecked && !newCustomerChecked && customerJobsite == "")
      {
        return false;
      }
      else
      {
        return true;
      }
    }

      const createAlert = async () => {
        
        const result = await formValid();
          if(result)
          {
            setLoading(true);
            try {
              const token = await getTokenSilently();
              const response = await fetch(appPaths.apiPath + "/api/createAlertStaff", {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',  // sent request
                  'Accept':       'application/json'   // expected data sent back
                },
                body: JSON.stringify({
                  description: description,
                  newCustomer: newCustomerChecked,
                  customer: customer,
                  companyName: companyName,
                  companyEmail: companyEmail,
                  companyPhone: companyPhone,
                  jobsiteStreetAddress: jobsiteStreetAddress,
                  city: city,
                  state: state,
                  zip: zip,
                  contactPersonFirstName: contactPersonFirstName,
                  contactPersonLastName: contactPersonLastName,
                  contactPersonPhone: contactPersonPhone,
                  contactMethod: setContactType,
                  contactType: contactType,
                  jobBuilding: (newCustomerChecked || newJobsiteChecked) ? building : customerJobsite,
                  jobLockbox: lockbox,
                  ladderNeeded: ladderNeeded,
                  salesperson: salesperson,
                  jobType : jobType
                })
              });
          
              const responseData = await response.json();
              setLoading(false);
              if(responseData.message)
              {
                setSnackbarMessage("Alert Succesfully Created!");
                setTimetrackerSnackbarOpen(true);
                alert("Alert Succesfully Created!")
                let data = {set: true, to: {pathname: "/", state: {}}};
                setToAlertDetails(data);
    
              }
              else
              {
                setSnackbarMessage("Alert Creation Failed!");
                setTimetrackerSnackbarOpen(true);
              }
          
            } catch (error) {
              console.error(error);
            }
          }
          else
          {
            alert("One or more required fields is blank!");
          }

        
      }
    useEffect(() => {
      if(newJobsiteChecked)
      {
        setJobsiteStreetAddress("");
        setCity("");
        setState("");
        setZip("");
      }
    }, [newJobsiteChecked])

    const options = [
        <MenuItem key="0" value="Company Email">Company Email</MenuItem>,
        <MenuItem key="1" value="Company Phone">Company Phone</MenuItem>,
        <MenuItem key="2" value="Contact Phone">Contact Phone</MenuItem>
      ];

      const jobTypeOptions = [
        <MenuItem key="0" value="New Construction">New Construction</MenuItem>,
        <MenuItem key="1" value="Repair">Repair</MenuItem>,
        <MenuItem key="3" value="Reroof">Reroof</MenuItem>,
        <MenuItem key="4" value="Roof Leak">Roof Leak</MenuItem>,
        <MenuItem key="5" value="Sheet Metal">Sheet Metal</MenuItem>,
        <MenuItem key="6" value="Curbs/Service">Curbs/Service</MenuItem>
      ];

      const handleTimetrackerSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setTimetrackerSnackbarOpen(false);
      };
       return (
      <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
        {toAlertDetails.set && 
        (<Redirect push to={toAlertDetails.to} />
      )}
        <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={timetrackerSnackbarOpen}
              autoHideDuration={5000}
              onClose={handleTimetrackerSnackbarClose}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{snackbarMessage}</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="close"
                  color="inherit"
                  //className={classes.close}
                  onClick={handleTimetrackerSnackbarClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
        <Zoom in={true}
          {...({ timeout: 500})}>
          
          <Paper style={{display: 'flex', justifyContent: 'center', flexDirection:'column', padding:'20px', margin: '20px'}}>
            {!loading && <>
              <img src={logo} className={styles.logo} alt="logo" />
              <h1>Staff Fix-It! Request</h1>
              <span style={{color:'red'}}>* = required</span><br />
              {/* Check to see if any items are found*/}
              <FormControl>
                <InputLabel>Description of Problem <span style={{color:'red'}}>*</span></InputLabel>
                <Input required type="text" placeholder="Enter Description of the Problem" name="description" value={description} onChange={(event => { setDescription(event.target.value) })}/>
              </FormControl>
              <Divider style={{marginTop:'40px', marginBottom: '31px'}} />
              <FormControl /*style={{backgroundColor: '#d9f2ff'}}*/>
              <FormControlLabel control={
                <Checkbox
                  checked={newCustomerChecked}
                  onChange={() => {setNewCustomerChecked(!newCustomerChecked)}}
                  value="newCustomerChecked"
                  inputProps={{
                    'aria-label': 'New Customer?',
                  }}
                />
              } label="New Customer?" />
              </FormControl>
              {newCustomerChecked &&
              <div style={{backgroundColor: '#d9f2ff'}}>
                <FormControl style={{width: '100%'}}>
                  <InputLabel>Company Name <span style={{color:'red'}}>*</span></InputLabel>
                  <Input required type="text" placeholder="Enter Company Name" name="companyName" value={companyName} onChange={(event => { setCompanyName(event.target.value) })}/>
                </FormControl>
                <FormControl style={{width: '100%'}}>
                  <InputLabel>Company Email <span style={{color:'red'}}>*</span></InputLabel>
                  <Input required type="email" placeholder="Enter email" name="companyEmail" value={companyEmail} onChange={(event => { setCompanyEmail(event.target.value) })}/>
                </FormControl>
                <FormControl style={{width: '100%'}}>
                  <InputLabel>Company Phone <span style={{color:'red'}}>*</span></InputLabel>
                  <Input required type="tel" placeholder="Enter Phone" name="companyPhone" value={companyPhone} onChange={(event => { setCompanyPhone(event.target.value) })}/>
                </FormControl>
              </div>
              }
              {!newCustomerChecked &&
              <>
                <FormControl /*style={{backgroundColor: '#d9f2ff'}}*/>
                  <InputLabel>Customer <span style={{color:'red'}}>*</span></InputLabel>
                  <Select
                    value={customer}
                    onChange={(event => {
                      setJobsiteStreetAddress(event.target.value.fieldData.Add_billing_line1);
                      setCity(event.target.value.fieldData.Add_billing_city);
                      setState(event.target.value.fieldData.Add_billing_state);
                      setZip(event.target.value.fieldData.Add_billing_zip);
                      setCustomer(event.target.value) })}
                    input={<Input id="customer" name="customer" />}
                  >
                    {customers.map(cust => 
                      <MenuItem value={cust} key={cust.fieldData.__ID}>{cust.fieldData.Name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </>
              }
              <Divider style={{marginTop:'40px', marginBottom: '25px'}} />
              {customer != "" && !newCustomerChecked &&
              <FormControl>
                <FormControlLabel control={
                  <Checkbox
                    checked={newJobsiteChecked}
                    onChange={() => { setNewJobsiteChecked(!newJobsiteChecked)}}
                    value="newJosbiteChecked"
                    inputProps={{
                      'aria-label': 'New Jobsite?',
                    }}
                  />
                } label="New Jobsite?" />
              </FormControl>
              }
              {(newCustomerChecked || newJobsiteChecked) &&
              <FormControl>
                <InputLabel>Name of Building/Job/Tenant:</InputLabel>
                <Input type="text" placeholder="Building/Tenant" name="building" value={building} onChange={(event => { setBuilding(event.target.value) })}/>
              </FormControl>
              }
              { !newCustomerChecked && !newJobsiteChecked && customer != "" &&
                <FormControl>
                  <InputLabel>Jobsite</InputLabel>
                  <Select
                    value={customerJobsite}
                    onChange={(event => {
                      let currentJobsite = null;
                      if(customerJobsites.filter(jobsite => jobsite.fieldData.Job_Name == event.target.value).length > 0)
                      {
                        currentJobsite = customerJobsites.filter(jobsite => jobsite.fieldData.Job_Name == event.target.value)[customerJobsites.filter(jobsite => jobsite.fieldData.Job_Name == event.target.value).length - 1]

                      }
                      if(currentJobsite != null)
                      {
                        setJobsiteStreetAddress(currentJobsite.fieldData.address_Street1_Ae);
                        setCity(currentJobsite.fieldData.address_City_Ae);
                        setState(currentJobsite.fieldData.address_State_Ae);
                        setZip(currentJobsite.fieldData.address_Zip_Ae);
                      }
                      setCustomerJobsite(event.target.value) })}
                    input={<Input id="jobsite" name="jobsite" />}
                  >
                    {[...new Set(customerJobsites.map(material => { return material.fieldData.Job_Name }))].map(cust => 
                      <MenuItem value={cust} key={cust}>{cust}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              }

              <FormGroup style={{flexDirection:'row'}}>
                <FormControl style={{width:'100%'}}>
                  
                  
                  {!(newCustomerChecked || newJobsiteChecked) && 
                    <>
                    <InputLabel>Jobsite Street Address:</InputLabel>
                    <Input type="text" placeholder="Jobsite Street Address" name="jobsiteStreetAddress" value={jobsiteStreetAddress} onChange={(event => { setJobsiteStreetAddress(event.target.value) })}/>
                    </>
                  }

                  {(newCustomerChecked || newJobsiteChecked) && 
                    <PlacesAutosuggest
                    changeValue={(value => { setJobsiteStreetAddress(value) })}
                    value={jobsiteStreetAddress}
                    changeChoice={(address) => {
                      setJobsiteStreetAddress(address.home + ' ' + address.street);
                      setCity(address.city);
                      setState(address.region);
                      setZip(address.postal_code);
                    }}
                    label="Jobsite Street Address"
                    placeholder="Jobsite Street Address"
                  />
                  }

                </FormControl>
                <FormControl style={{width:'33.3%'}}>
                  <InputLabel>City:</InputLabel>
                  <Input type="text" placeholder="City" name="city" value={city} onChange={(event => { setCity(event.target.value) })}/>
                </FormControl>
                <FormControl style={{width:'33.4%'}}>
                  <InputLabel>State:</InputLabel>
                  <Input type="text" placeholder="State" name="state" value={state} onChange={(event => { setState(event.target.value) })}/>
                </FormControl>
                <FormControl style={{width:'33.3%'}}>
                  <InputLabel>Zip:</InputLabel>
                  <Input type="text" placeholder="Zip" name="zip" value={zip} onChange={(event => { setZip(event.target.value) })}/>
                </FormControl>
              </FormGroup>
              <FormControl>
                  <InputLabel>Salesperson</InputLabel>
                  <Select
                    value={salesperson}
                    onChange={(event => {
                      setSalesperson(event.target.value) })}
                    input={<Input id="customer" name="customer" />}
                  >
                    {salespeople.map(cust => 
                      <MenuItem value={cust} key={cust.fieldData.__ID}>{cust.fieldData.Name_combined_ae}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              <FormControl>
                <InputLabel>Contact Person First Name:</InputLabel>
                <Input type="text" placeholder="Enter First Name" name="contactFirstName" value={contactPersonFirstName} onChange={(event => { setContactPersonFirstName(event.target.value) })}/>
              </FormControl>
              <FormControl>
                <InputLabel>Contact Person Last Name:</InputLabel>
                <Input type="text" placeholder="Enter Last Name" name="contactLastName" value={contactPersonLastName} onChange={(event => { setContactPersonLastName(event.target.value) })}/>
              </FormControl>
              <FormControl>
                <InputLabel>Contact Person Phone:</InputLabel>
                <Input type="text" placeholder="EnterPhone" name="contactPhone" value={contactPersonPhone} onChange={(event => { setContactPersonPhone(event.target.value) })}/>
              </FormControl>
              <FormControl>
                <InputLabel>Contact Method:</InputLabel>
                <Select
                  value={contactType}
                  onChange={(event => { setContactType(event.target.value) })}
                  input={<Input id="contactType" name="contactType" />}
                >
                  {options}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Job Type:</InputLabel>
                <Select
                  value={jobType}
                  onChange={(event => { setJobType(event.target.value) })}
                  input={<Input id="jobType" name="jobType" />}
                >
                  {jobTypeOptions}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Roof Access Info/Lockbox Code/Tech Notes:</InputLabel>
                <Input type="text" placeholder="Roof Access Info/Lockbox Code" name="lockboxCode" value={lockbox} onChange={(event => { setLockbox(event.target.value) })}/>
              </FormControl>
              <FormControl>

              <FormControlLabel control={
                <Checkbox
                  checked={ladderNeeded}
                  onChange={() => {setLadderNeeded(!ladderNeeded)}}
                  value="ladder"
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />
              } label="Ladder Needed?" />
              </FormControl>
              <br></br>
              <Button variant="contained" color="primary" onClick={() => { createAlert() }}>
                Submit
              </Button>
            </>
            }
            {loading && <CircularProgress></CircularProgress>}
          </Paper>
        </Zoom>
    </div>
    );
}