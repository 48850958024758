import React, { Component, useState, useEffect } from 'react';
import styles from '../home.module.css';
import { useAuth0 } from "../../react-auth0-wrapper";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Redirect } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import appPaths from '../../applicationPaths.json';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Logo from '../../assets/images/logo.png';
import loginImage from '../../assets/images/logo.png';
import LeakImage from '../../assets/images/residential-roofing-minneapolis.jpg';
import ButtonBase from '@material-ui/core/ButtonBase';
import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import TimerIcon from '@material-ui/icons/Timer';
import WorkIcon from '@material-ui/icons/Work';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BuildIcon from '@material-ui/icons/Build';

const images = [
  // {
  //   url: loginImage,
  //   title: 'Staff Login',
  //   width: '100%',

  // },
  {
    url: LeakImage,
    title: 'Report Leak',
    width: '100%',
  },
];
const authImages = [
  {
    title: 'Manage Alerts',
    width: '100%',
    permissions:["type:admin"]
  },
  {
    title: 'Track Time',
    width: '100%',
    permissions:[]
  },
  {
    title: 'Report Leak',
    width: '100%',
    permissions:[]
  },
  {
    title: 'Manage Jobs',
    width: '100%',
    permissions:[]
  },
  {
    title: 'Create User',
    width: '100%',
    permissions:["type:admin"]
  },
]


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    marginTop: '0px'
  },
  table: {
    width: '100%'
  },
  card: {
    width: 345,
  },
  media: {
    height: 140,
  },
  image: {
    position: 'relative',
    height: 200,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      height: 175,

    },
    [theme.breakpoints.down('md')]: {
      height: 150,

    },
    [theme.breakpoints.down('sm')]: {
      height: 125,

    },

    [theme.breakpoints.down('xs')]: {
      height: 100,

    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  loginRoot: {
    paddingTop: 25,
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}







const Home = () => {

  const [value, setValue] = React.useState(0);
  const { isAuthenticated, loginWithRedirect, logout, loading } = useAuth0();
  const classes = useStyles();
  const [showResult, setShowResult] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [updateAlerts, setUpdateAlerts] = useState(true);
  const [apiMessage, setApiMessage] = useState([]);
  const [unassignedAlerts, setUnassignedAlerts] = useState([]);
  const [assignedAlerts, setAssignedAlerts] = useState([]);
  const theme = useTheme();
  const [userPermissions, setUserPermissions] = useState(null);



  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }
 
  const [toAlertDetails, setToAlertDetails] = useState({set: false, location: "/alert-details"});
  const { getTokenSilently } = useAuth0();

  const handleClick = (id) => {
    let data = {set: true, to: {pathname: "/alert-details", state: {__ID: id.fieldData.__ID}}};
    setToAlertDetails(data);
  }
  useEffect(() => {
    if(localStorage.getItem('unassignedAlerts'))
    {
      setShowResult(true);
      setUnassignedAlerts(JSON.parse(localStorage.getItem('unassignedAlerts')));
    }
  }, []);
  const handleButton = (i) => {
    if(i == 0)
    {
      //loginWithRedirect({})
      let data = {set: true, to: {pathname: "/report-leak", state: {}}};
      setToAlertDetails(data);
    }

  };
  const handleAuthButton = (i) => {
    let data = {};
    switch (i)
    {
      case 0:
        data = {set: true, to: {pathname: "/alert-list", state: {}}};
        setToAlertDetails(data);
        break;
      case 1:
        data = {set: true, to: {pathname: "/timecard", state: {}}};
        setToAlertDetails(data);
        break;
      case 2:
        data = {set: true, to: {pathname: "/report-leak-staff", state: {}}};
        setToAlertDetails(data);
        break;
      case 3:
        data = {set: true, to: {pathname: "/jobs-list", state: {}}};
        setToAlertDetails(data);
        break;
      case 4:
        data = {set: true, to: {pathname: "/create-user", state: {}}};
        setToAlertDetails(data);
        break;
      default:
          data = {set: true, to: {pathname: "/", state: {}}};
          setToAlertDetails(data);
    }

  };

  useEffect(() => {

    if(updateAlerts && isAuthenticated)
    {
      setUpdateAlerts(false);
      const callApi = async () => {
        try {
          const token = await getTokenSilently();
    
          const response = await fetch(appPaths.apiPath + "/api/external", {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
    
          const responseData = await response.json();
          if(responseData.message)
          {
            let theUnassignedAlerts = [];
            let theAssignedAlerts = [];
            responseData.data.response.data.forEach(alert => {
              if(alert.fieldData.JobCreated_flag == 1)
              {
                theAssignedAlerts.push(alert);
              }
              else
              {
                theUnassignedAlerts.push(alert);
              }
            });
            setAssignedAlerts(theAssignedAlerts);
            setUnassignedAlerts(theUnassignedAlerts);
            setShowResult(true);
            setUpdateAlerts(false);
            setUpdated(true);
            localStorage.setItem('unassignedAlerts', JSON.stringify(theUnassignedAlerts));
            localStorage.setItem('assignedAlerts', JSON.stringify(theAssignedAlerts));
          }
          else
          {
            setShowResult(true);
            setUpdateAlerts(false);
            alert(responseData);
          }

        } catch (error) {
          setUpdateAlerts(true);
          console.error(error);
          setShowResult(false);
        }
      };
        callApi();
    }
  
}, [getTokenSilently, isAuthenticated, updateAlerts]);

useEffect(() => {
  const getPermissions = async () => {
    try {
      const token = await getTokenSilently();
      const response = await fetch(appPaths.apiPath + "/api/getUserPermissions", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const responseData = await response.json();
      console.log(responseData);
      setUserPermissions(responseData);
    }
    catch (error) {
      console.error(error);
    }
  };
  if(!loading)
  {
    getPermissions();
  }

},[getTokenSilently, loading]);
    return (



      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
      {toAlertDetails.set && 
        (<Redirect push to={toAlertDetails.to} />
      )}
        {!isAuthenticated && (
          <div className={classes.loginRoot}>
      {images.map((image, i) => (
        <Zoom key={image.title} in={true}
        {...({ timeout: 500 * (i + 1)})}>
        <ButtonBase
        onClick={() =>
          handleButton(i)
        }
          focusRipple

          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
          style={{
            width: image.width,
            margin: '25px',
          }}
        >
          {/*i == 0 && <span
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image.url})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
          />*/}
          {/*i > 0 &&
          <span
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image.url})`,
            }}
          />*/}
          <span className={classes.imageBackdrop} />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
              style={{fontSize : '1.25rem'}}
            >
              <BuildIcon style={{ fontSize: 25 }}/><br />
              {image.title}
              {/*<span className={classes.imageMarked} />*/}
            </Typography>
          </span>
        </ButtonBase>
        </Zoom>
      ))}
    </div>
        )}

        {isAuthenticated && userPermissions && (
            <Grid container spacing={3} style={{width: '100%', paddingTop: theme.spacing(3)}}>
      {authImages.map((image, i) => {
        if((image.permissions.filter(x => { return userPermissions.includes(x) }).length > 0 || image.permissions.length == 0))
        {
        return (
        <Grid  key={image.title} item xs={12} sm={6} md={4} lg={3}> 
        <Zoom in={true}
        {...({ timeout: 800 * (i + 1) / authImages.length})}>
        <ButtonBase
        onClick={() =>
          handleAuthButton(i)
        }
          focusRipple
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
          style={{
            width: image.width,
            margin: '0px',
          }}
        >
          <span className={classes.imageBackdrop} />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
              style={{fontSize : '1.25rem'}}
            >
              {
                image.title == 'Manage Alerts' && <NotificationsIcon style={{ fontSize: 25 }}/>
              }
              {
                image.title == 'Track Time' && <TimerIcon style={{ fontSize: 25 }}/>
              }
              {
                image.title == 'Report Leak' && <BuildIcon style={{ fontSize: 25 }}/>
              }
              {
                image.title == 'Manage Jobs' && <WorkIcon style={{ fontSize: 25 }}/>
              }
              {
                image.title == 'Create User' && <PersonAddIcon style={{ fontSize: 25 }}/>
              }
              <br />
              {image.title}

            </Typography>
          </span>
        </ButtonBase>
        </Zoom>
        </Grid>

              )}
              else{
                return null;
              }})}
              </Grid>
        )}
      </div>
    );

}
export default Home;