import React, {useState, useRef, useEffect} from 'react';
import { GoogleMap, LoadScript, useGoogleMap, Polygon } from '@react-google-maps/api';
import {CircularProgress, Card, List, ListItem, Badge, Divider, ListItemText, ListItemIcon, Checkbox} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import appPaths from '../../applicationPaths.json';
import { useAuth0 } from "../../react-auth0-wrapper";
import RoomIcon from '@material-ui/icons/Room';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import theme from '../../themes/theme';
import ListSubheader from '@material-ui/core/ListSubheader';


const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    paper: {
      margin: theme.spacing(3),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100%',
    },
    grow: {
      flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flexDirection: 'column',
    },
    modalPaper: {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flexDirection: 'column',
    },
    button: {
      margin: theme.spacing(1),
      width: '100%',
      textAlign: 'left',
      alignItems: 'left',
      justifyContent: 'left',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }));

export default function InvoiceMarkers(props) {

    const [loading, setLoading] = useState(true);
    const mapRef = useRef(null);
    const classes = useStyles();
    const [features, setFeatures] = useState([]);
    const [center, setCenter] = useState({
        lat: 45.156,
        lng: -93.3894,
      });
      const { isAuthenticated, loginWithRedirect, logout, getTokenSilently } = useAuth0();



      useEffect(() => {
        const getJobMapData = async () => {
          try {
            const token = await getTokenSilently();
            const response = await fetch(appPaths.apiPath + "/api/getJobMapData", {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
              },
              body: JSON.stringify({id: props.jobId})
            });
      
            const responseData = await response.json();
            if(responseData.message)
            {
              setCenter(responseData.data.center);
              props.setCenter(responseData.data.center);
              //console.log(responseData.data.center);
              setLoading(false);
              mapRef.current.data.addGeoJson(responseData.data.mapData);
              if(responseData.data.bounds != "")
              {
                var bounds = new window.google.maps.LatLngBounds();
                var myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMin, responseData.data.bounds.lngMin);
                bounds.extend(myLatLng);
                myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMin, responseData.data.bounds.lngMax);
                bounds.extend(myLatLng);
                myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMax, responseData.data.bounds.lngMin);
                bounds.extend(myLatLng);
                myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMax, responseData.data.bounds.lngMax);
                bounds.extend(myLatLng);
                mapRef.current.fitBounds(bounds);
                //console.log(bounds.getSouthWest().lat() - bounds.getNorthEast().lat());
                //console.log(bounds.getSouthWest().lng() - bounds.getNorthEast().lng());
                const distance = Math.max(Math.abs(bounds.getSouthWest().lat() - bounds.getNorthEast().lat()), Math.abs(bounds.getSouthWest().lng() - bounds.getNorthEast().lng()));
                const multiplier = 0.00017;
                props.setCenter({lat: (bounds.getSouthWest().lat() + bounds.getNorthEast().lat()) / 2, lng: (bounds.getSouthWest().lng() + bounds.getNorthEast().lng()) / 2});
                //console.log(distance);
                var zoom = 1;
                for(let i = 0; distance > Math.pow(2, i) * multiplier; i++) {
                  zoom = 20 - i;
                }
                props.setZoom(zoom);
  
              }
              const mapData = [];
  
              mapRef.current.data.forEach(feature => {
                mapData[feature.getProperty('id')] = {
                  type: feature.getGeometry().getType(),
                  label: feature.getProperty('label'),
                  color: feature.getProperty('color'),
                  description: feature.getProperty("description"),
                  coords: []
                };
                feature.getGeometry().forEachLatLng((point) => {
                  mapData[feature.getProperty('id')].coords.push(point);
                });
                  
              });
              //console.log(mapData);
              props.setImageMapData(mapData);
              
              //setJobDetails(responseData.data.response.data[0].fieldData);
              //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
            }
            else
            {
                setLoading(false);
                
              //alert(responseData);
            }
    
          } catch (error) {
            console.error(error);
          }
        };
        getJobMapData();
      }, [getTokenSilently, props.jobId, props.setCenter, props.setZoom, props.setImageMapData]);


      useEffect(() => {
        if(!loading)
        {
          mapRef.current.data.forEach(feature => {
            
            if(props.features.filter(image => image.getProperty('id') == feature.getProperty('id')).length > 0)
            {
                if (feature.getGeometry().getType() === "Polygon") {
                    mapRef.current.data.overrideStyle(feature, {strokeWeight: 7});
                }
                else
                {
                  if(feature.getProperty('label') != null)
                  {
                    mapRef.current.data.overrideStyle(feature, {label:{color: theme.palette.secondary.main, fontWeight: 'bold', text: feature.getProperty('label')}});
                  }
                  else
                  {
                    mapRef.current.data.overrideStyle(feature, {strokeWeight: 7, label:{color: theme.palette.secondary.main, fontWeight: 'bold', text: 'New'}});
                  }
                }
            }
            else
            {
              mapRef.current.data.revertStyle(feature);
            }
            
        });
        }
            
      }, [props.selectedImages])

      useEffect(() => {
        if(!loading)
        {
          const newFeatures = [];
          mapRef.current.data.forEach(feature => {
            newFeatures.push(feature);
          });
          setFeatures(newFeatures);
        }
      }, [loading]);

      function handleSelectAllClick(event) {
        if(event.target.checked)
        {
          props.setFeatures(features);
        }
        else
        {
          props.setFeatures([]);
        }
      }

      function handleListItemClick(event, index) {
        if(props.features.filter(image => image.getProperty('id') == index.getProperty('id')).length > 0)
        {
          props.setFeatures(prevState => prevState.filter(image => image.getProperty('id') != index.getProperty('id')));
          props.removeImages(index.getProperty('id'));
          mapRef.current.data.revertStyle(index);
          
        }
        else
        {
          props.setFeatures(prevState => [...prevState, index]);
          props.addImages(index.getProperty('id'));
          let feature = index;
          if(feature.getGeometry().getType() === "Polygon") {
            mapRef.current.data.overrideStyle(feature, {strokeWeight: 7});
          }
          else
          {
            if(feature.getProperty('label') != null)
            {
              mapRef.current.data.overrideStyle(feature, {label:{color: theme.palette.secondary.main, fontWeight: 'bold', text: feature.getProperty('label')}});
            }
            else
            {
              mapRef.current.data.overrideStyle(feature, {strokeWeight: 7, label:{color: theme.palette.secondary.main, fontWeight: 'bold', text: 'New'}});
            }
          }
        }
        
      }

    return (
        <div style={{width: '100%', display:'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center'}}>
            <div style={{width:'100%', height: '35vh', zIndex:'900'}} id="googleMarkerMap">
            {/*<LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyA0S-SHVXNnPwU7Y9ucd0sh2L-o9KPC_sQ"
            style={{height:'100%', width:'100%'}}
            >*/}

                <div style={{display:loading ? "none" : "block", height:'100%', width:'100%'}} >

                    <GoogleMap
                        id="circle-example"
                        onLoad={map => {
                          mapRef.current = map;
                          props.setMapRef(map);
                          mapRef.current.data.setStyle(function(feature) { return {fillColor: feature.getProperty('color'), strokeColor: feature.getProperty('color'), label: {text: feature.getProperty('label')}}; });
                          //mapRef.current.data.setStyle({draggable: true, editable: true});
                          //mapRef.current.data.addListener('click', event => mapClick(event));
                          
                        }}
                        mapContainerStyle={{
                            height: "100%",
                            width: "100%"
                        }}
                        zoom={17}
                        center={center}
                        tilt={0}
                        options={{
                            streetViewControl: false,
                            mapTypeControl: false,
                            mapTypeId: "satellite",
                            rotateControl: false,
                        }}
                    >
                    </GoogleMap>

                </div>

                {loading && <div style={{width:'100%', display: 'flex', justifyContent: 'center', textAlign: 'center'}}><CircularProgress /></div>}
            {/*</LoadScript>*/}
            </div>
            {!loading && 
            <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px'}}>
              <List className={classes.list}
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Select Markers and Roof Sections to Appear on Invoice
                </ListSubheader>
              }>
                <ListItem
                  style={{backgroundColor: theme.palette.secondary.main + "22"}}
                >
                  <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={features.length === props.features.length}
                        tabIndex={-1}
                        disableRipple
                        onClick={handleSelectAllClick}
                    />
                  </ListItemIcon>
                  <ListItemText primary={features.length === props.features.length ? "Deselect All" : "Select All"} />
                </ListItem>
              <>
              <Divider />
              {features.sort((a, b) => (a.getProperty('id') > b.getProperty('id')) ? -1 : 1).map((feature, i) =>
              (
              <div key={feature.getProperty('id')}>
              <ListItem
              button
              selected={props.features.filter(feature1 => feature1.getProperty('id') == feature.getProperty('id')).length > 0}
              onClick={event => handleListItemClick(event, feature)}
              >
                <ListItemIcon>
                  <Checkbox
                      edge="start"
                      checked={props.features.filter(feature1 => feature1.getProperty('id') == feature.getProperty('id')).length > 0}
                      tabIndex={-1}
                      disableRipple
                      onClick={event => handleListItemClick(event, feature)}
                  />
                </ListItemIcon>
                <ListItemText primary={feature.getProperty("description")} />
                {feature.getGeometry().getType() == "Point" && <ListItemIcon><Badge color="primary" badgeContent={feature.getProperty('label')}><RoomIcon style={{color: 'red'}}/></Badge></ListItemIcon>}
                {feature.getGeometry().getType() == "Polygon" && <ListItemIcon><ChangeHistoryIcon style={{color: feature.getProperty('color')}}/></ListItemIcon>}
                  

              </ListItem>
              <Divider />
              
              </div>
              )
              )}
                            
              </>
                </List>
            </Card>
            }
        </div>
    );
};