/*
**
** Main parent component for displaying the Job Map
**
*/

//Imports
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { GoogleMap, LoadScript, useGoogleMap, Polygon } from '@react-google-maps/api';
import { useAuth0 } from "../react-auth0-wrapper";
import appPaths from '../applicationPaths.json';
import {CircularProgress, Button, Grid, Paper, IconButton, AppBar, Toolbar, Backdrop, Modal, Fade, Tab, Tabs, Typography, Box} from '@material-ui/core';
import {Card, Zoom} from '@material-ui/core';
import theme from '../themes/theme';
import ReactDom from 'react-dom';
import { SwatchesPicker, SketchPicker, CompactPicker } from 'react-color';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MarkerDetails from './Map/MarkerDetails';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Redirect } from 'react-router';
import { Autocomplete, Marker } from '@react-google-maps/api';
import JobImages from './Map/JobImageList';
import JobGallery from './Map/JobGallery';
import RoomIcon from '@material-ui/icons/Room';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//function to create tab panels. used for various tabs one can navigate to
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      style={{width:'100%',}}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
}

//Style definitions
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  paper: {
    margin: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  modalPaper: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  button: {
    margin: theme.spacing(1),
    width: '100%',
    textAlign: 'left',
    alignItems: 'left',
    justifyContent: 'left',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

//Main component function declaration and export
export default function MyComponent(props)
{
  const [center, setCenter] = useState({
    lat: 44.156,
    lng: -90.3894,
  });
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, loginWithRedirect, logout, getTokenSilently, user } = useAuth0();
  const [roofArea, setRoofArea] = useState(0);
  const [roofPerimeter, setRoofPerimeter] = useState(0);
  const [currentFeature, setCurrentFeature] = useState(null);
  const [currentLabel, setCurrentLabel] = useState('');
  const classes = useStyles();
  const [newCenter, setNewCenter] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [newPicOpen, setNewPicOpen] = useState(false);
  const [redirectDetails, setRedirectDetails] = useState({set: false, location: "/job-camera"});
  const [detailsTab, setDetailsTab] = useState('');
  const [images, setImages] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(true);
  const [newBounds, setNewBounds] = useState(null);
  const [tab, setTab] = React.useState(2);
  const [changes, setChanges] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [image, setImage] = useState({url:null});

  const mapRef = useRef(null);




  const getImages = async () => {
    try {
      const token = await getTokenSilently();
      const response = await fetch(appPaths.apiPath + "/api/getJobImages", {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',  // sent request
          'Accept':       'application/json'   // expected data sent back
        },
        body: JSON.stringify({id: props.location.state.__ID})
      });

      const responseData = await response.json();
      if(responseData.message)
      {
        let imageData = responseData.data.response.data;
        imageData.forEach(image => {
          image.changed = false;
          image.deleted = false;
        });
        setImages(imageData);
        setImagesLoading(false);
        
        //setJobDetails(responseData.data.response.data[0].fieldData);
        //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
      }
      else
      {
        setImagesLoading(false);
        //alert(responseData);
      }

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImages();
  }, [loading, props.location.state.__ID]);

  useEffect(() => {
    if(!loading)
    {
      setImage({
        url: user.picture,
        scaledSize: new window.google.maps.Size(25, 25)
      });
      mapRef.current.data.revertStyle();
      if(currentFeature != null)
      {


      if (currentFeature.getGeometry().getType() === "Polygon") {
          mapRef.current.data.overrideStyle(currentFeature, {editable: true, draggable: true, strokeWeight: 7});
      }
      else
      {
        if(currentFeature.getProperty('label') != null)
        {
          mapRef.current.data.overrideStyle(currentFeature, {editable: true, draggable: true, label:{color: theme.palette.secondary.main, fontWeight: 'bold', text: currentFeature.getProperty('label')  + ""}});
        }
        else
        {
          mapRef.current.data.overrideStyle(currentFeature, {editable: true, draggable: true, strokeWeight: 7, label:{color: theme.palette.secondary.main, fontWeight: 'bold', text: 'New'}});
        }
      }
    }
    }
    
  },[currentFeature, loading])




  const mapClick = (event) => {
      var label = event.feature.getProperty('label');
      if(label == null)
      {
        setCurrentLabel('');
      }
      else
      {
        setCurrentLabel(label);
      }
      setCurrentFeature(event.feature);

      
            /*
      currentFeature.getPath().addListener('remove_at', function(){
        // Point was removed
        currentFeature.setProperty('area', window.google.maps.geometry.spherical.computeArea(currentFeature.getPath()));
      });
    
      currentFeature.getPath().addListener('set_at', function(){
        // Point was moved
        currentFeature.setProperty('area', window.google.maps.geometry.spherical.computeArea(currentFeature.getPath()));
      });
      */
  };

  

  const trackLocation = ({ onSuccess, onError = () => { } }) => {
    if ('geolocation' in navigator === false) {
      return onError(new Error('Geolocation is not supported by your browser.'));
    }
  
    // Use watchPosition instead.
    return navigator.geolocation.watchPosition(onSuccess, onError);
  };

    



  useEffect(() => {
    if(newBounds != "" &&  !loading)
        {
          var bounds = new window.google.maps.LatLngBounds();
          var myLatLng = new window.google.maps.LatLng(newBounds.latMin, newBounds.lngMin);
          bounds.extend(myLatLng);
          myLatLng = new window.google.maps.LatLng(newBounds.latMin, newBounds.lngMax);
          bounds.extend(myLatLng);
          myLatLng = new window.google.maps.LatLng(newBounds.latMax, newBounds.lngMin);
          bounds.extend(myLatLng);
          myLatLng = new window.google.maps.LatLng(newBounds.latMax, newBounds.lngMax);
          bounds.extend(myLatLng);
          mapRef.current.fitBounds(bounds);
        }
  }, [newBounds]);


  useEffect(() => {
    const getJobMapData = async () => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(appPaths.apiPath + "/api/getJobMapData", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
          body: JSON.stringify({id: props.location.state.__ID})
        });
  
        const responseData = await response.json();
        if(responseData.message)
        {
          setCenter(responseData.data.center);
          setLoading(false);
          if(responseData.data.mapData) {
            mapRef.current.data.addGeoJson(responseData.data.mapData);
          }

          if(typeof responseData.data.bounds !== 'undefined')
          {
            var bounds = new window.google.maps.LatLngBounds();
            var myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMin, responseData.data.bounds.lngMin);
            bounds.extend(myLatLng);
            myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMin, responseData.data.bounds.lngMax);
            bounds.extend(myLatLng);
            myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMax, responseData.data.bounds.lngMin);
            bounds.extend(myLatLng);
            myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMax, responseData.data.bounds.lngMax);
            bounds.extend(myLatLng);
            mapRef.current.fitBounds(bounds);
          }
          
          //setJobDetails(responseData.data.response.data[0].fieldData);
          //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
        }
        else
        {
          if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setLoading(false);
            
          });
        }
        else
        {
          setLoading(false);
        }
            
          //alert(responseData);
        }
  
      } catch (error) {
        console.error(error);
      }
    };  
    getJobMapData();
  },[getTokenSilently, props.location.state.__ID]);

  const updateMapData = () => {
    mapRef.current.data.toGeoJson(object => {
      sendUpdatedMapData(object);
    })
    
  };





  const sendUpdatedMapData = async (object) => {
    const getJobMapData = async () => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(appPaths.apiPath + "/api/getJobMapData", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
          body: JSON.stringify({id: props.location.state.__ID})
        });
  
        const responseData = await response.json();
        if(responseData.message)
        {
          setCenter(responseData.data.center);
          setLoading(false);
          mapRef.current.data.addGeoJson(responseData.data.mapData);
          if(responseData.data.bounds != "")
          {
            var bounds = new window.google.maps.LatLngBounds();
            var myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMin, responseData.data.bounds.lngMin);
            bounds.extend(myLatLng);
            myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMin, responseData.data.bounds.lngMax);
            bounds.extend(myLatLng);
            myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMax, responseData.data.bounds.lngMin);
            bounds.extend(myLatLng);
            myLatLng = new window.google.maps.LatLng(responseData.data.bounds.latMax, responseData.data.bounds.lngMax);
            bounds.extend(myLatLng);
            mapRef.current.fitBounds(bounds);
          }
          
          //setJobDetails(responseData.data.response.data[0].fieldData);
          //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
        }
        else
        {
          if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setLoading(false);
            
          });
        }
        else
        {
          setLoading(false);
        }
            
          //alert(responseData);
        }
  
      } catch (error) {
        console.error(error);
      }
    };  
      try {
        setLoading(true);
        setImagesLoading(true);
        setCurrentFeature(null);
        const token = await getTokenSilently();
        const response = await fetch(appPaths.apiPath + "/api/updateJobMapData", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
          body: JSON.stringify({id: props.location.state.__ID, center: newCenter, mapData: object, bounds: bounds, images: images})
        });
  
        const responseData = await response.json();
        if(responseData.message)
        {
          mapRef.current.data.forEach(function(feature) {
            // If you want, check here for some constraints.
            mapRef.current.data.remove(feature);
          });
          getJobMapData();
          getImages();
          
          //setJobDetails(responseData.data.response.data[0].fieldData);
          //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
        }
        else
        {
            setLoading(false);
            setImagesLoading(false);
          //alert(responseData);
        }

      } catch (error) {
        console.error(error);
      }
    };


  const addToCoords = (feature) => {
    const lat = [];
    const lng = [];
    feature.getGeometry().forEachLatLng((currentLatLng) => {
      lat.push(currentLatLng.lat());
      lng.push(currentLatLng.lng());
    });
    
    
  };

  useEffect(() => {

    const sendUpdatedMapDataSilently = async (object) => {
      try {
        let sendImages = JSON.parse(JSON.stringify(images)); //create a deep copy of the array to retain original changed flags before resetting
        setImages(oldImages => oldImages.filter(image => image.deleted == false).map(image => { let newImage = image; newImage.changed = false; return newImage; }));

        const token = await getTokenSilently();
        const response = await fetch(appPaths.apiPath + "/api/updateJobMapData", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
          body: JSON.stringify({id: props.location.state.__ID, center: newCenter, mapData: object, bounds: bounds, images: sendImages})
        });
        
        const responseData = await response.json();
        if(responseData.message)
        {
          setSaveLoading(false);
          //setJobDetails(responseData.data.response.data[0].fieldData);
          //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
        }
        else
        {
          //alert(responseData);
        }
  
      } catch (error) {
        console.error(error);
      }
    };

    if(changes && !saveLoading)
    {
      setChanges(false);
      setSaveLoading(true);
      mapRef.current.data.toGeoJson(object => {
        sendUpdatedMapDataSilently(object);
      });
    }
  }, [saveLoading, changes, images, getTokenSilently, props.location.state.__ID, newCenter, bounds]);

  useEffect(() => {
    if(newCenter == null)
    {
      const lat = [];
      const lng = [];
      if(!loading)
      {
        mapRef.current.data.forEach((feature) => {
          
          feature.getGeometry().forEachLatLng((currentLatLng) => {
            lat.push(currentLatLng.lat());
            lng.push(currentLatLng.lng());
          });
        });
      }
      
      var sum = 0;
      var latAvg = 0;
      var lngAvg = 0;
      if (lat.length)
      {
          sum = lat.reduce(function(a, b) { return a + b; });
          latAvg = sum / lat.length;
      }
      if (lng.length)
      {
          sum = lng.reduce(function(a, b) { return a + b; });
          lngAvg = sum / lng.length;
      }
      setBounds({latMin: Math.min(...lat), latMax: Math.max(...lat),lngMin: Math.min(...lng), lngMax: Math.max(...lng)})
      setNewCenter({lat: latAvg, lng: lngAvg});
    }
    

  }, [newCenter, loading]);

  const findFeature = (id) => {
    if(id == "")
    {
      setCurrentFeature(null);
    }
    else
    {
      mapRef.current.data.forEach(feature => {
        if(feature.getProperty('id') == id)
        {
          setCurrentFeature(feature);
        }
      });
    }

  };

  useEffect(() =>{
    if(tab == 1 && !loading)
    {
      
      mapRef.current.data.setControls(null);
    }
    else if(!loading)
    {
      mapRef.current.data.setControls(['Point', 'Polygon']);
    }

  }, [loading, tab]);

  const handleClose = () => {
    setNewPicOpen(false);
  };

  const handleNewPictureClick = () => {
    if(currentFeature != null)
    {
      let data = {set: true, to: {pathname: "/job-camera", state: {__ID: props.location.state.__ID, currentFeature: currentFeature.getProperty('id')}}};
      setRedirectDetails(data);
    }
    else
    {
      let data = {set: true, to: {pathname: "/job-camera", state: {__ID: props.location.state.__ID, currentFeature: null}}};
      setRedirectDetails(data);
    }

  };

  const handleNewUploadPictureClick = () => {
    if(currentFeature != null)
    {
      let data = {set: true, to: {pathname: "/job-upload", state: {__ID: props.location.state.__ID, currentFeature: currentFeature.getProperty('id')}}};
      setRedirectDetails(data);
    }
    else
    {
      let data = {set: true, to: {pathname: "/job-upload", state: {__ID: props.location.state.__ID, currentFeature: null}}};
      setRedirectDetails(data);
    }

  };

  const handleTabChange = (event, val) => {
    if(val == 1 || val == 2)
    setTab(val)
  };

const imageGallery = useMemo(() => {
  if(!loading) {
  return (
  <JobGallery
    setImage={(image, newImage) => {
      images.filter(i => i === image).forEach(i => {
        i = newImage;
        setChanges(true);
      })
    }}
    setChanges={setChanges}
    newBounds={newBounds}
    changeNewBounds={(val) => setNewBounds(val)}
    changeCenter={(val) => setCenter(val)}
    images={images}
    currentFeature={currentFeature}
    findFeature={findFeature}
    loading={imagesLoading}
    features={mapRef.current.data}
    changeCurrentFeature={(val) => setCurrentFeature(val)}
    setNewPicOpen={setNewPicOpen}
  />
);
}
else
{
  return (<div>Hello</div>);
}
}, [loading, newBounds, images, currentFeature, imagesLoading]);

  return (
    <>

    {redirectDetails.set && 
      (<Redirect push to={redirectDetails.to} />
    )}
      <Card style={{width:'100%', display: 'flex', justifyContent: 'center', textAlign: 'center', borderRadius: '0px'}}>
        <Toolbar style={{width:'100%', display: 'flex', justifyContent: 'center', textAlign: 'center', borderRadius: '0px'}}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example"
            style={{width: '100%'}}
          >
            
            {/*<Tab value={0} icon={<CloseIcon />} onClick={() => {
                  setCurrentFeature(null);
                setLoading(true); setCurrentFeature(null); mapRef.current.data.forEach(function(feature) {
                    mapRef.current.data.remove(feature);
                });
              getJobMapData()}} />*/}
                <Tab value={0} icon={<ArrowBackIcon />} onClick={() => { setRedirectDetails({set: true, to: {pathname: "/job", state: {__ID: props.location.state.__ID}}}) }} />
                <Tab label="Manage Markers" value={2} icon={<RoomIcon />} />
                <Tab label="Manage Images" value={1} icon={<PhotoLibraryIcon />} onClick={() => {setCurrentFeature(null)}} />
                {/*<Tab value={3} icon={<AddAPhotoIcon />} onClick={() => { setNewPicOpen(true); }} />*/}
                {/*<Tab value={4} icon={<DoneIcon />} onClick={() => { updateMapData() }} />*/}

          </Tabs>
        </Toolbar>
      </Card>
      {/*<div className={classes.appBarSpacer}></div>*/}
    <div style={{width:'100%', height: '50vh'}}>
      {/* <LoadScript
      id="script-loader"
      googleMapsApiKey="AIzaSyA0S-SHVXNnPwU7Y9ucd0sh2L-o9KPC_sQ"
      style={{height:'100%', width:'100%'}}
      > */}

          <div style={{display:loading ? "none" : "block", height:'100%', width:'100%'}}>

              <GoogleMap
                  id="circle-example"
                  onLoad={map => {
                    

                    mapRef.current = map;
                    mapRef.current.data.setStyle(function(feature) { return {geodesic: true, fillColor: feature.getProperty('color'), strokeColor: feature.getProperty('color'), label: {text: feature.getProperty('label')}}; });
                    //mapRef.current.data.setStyle({draggable: true, editable: true});
                    mapRef.current.data.setControls(['Point', 'Polygon']);
                    mapRef.current.data.addListener('addfeature', function(event){
                      
                      setNewCenter(null);
                      var time = new Date();
                      if(event.feature.getGeometry().getType() == "Point")
                      {
                        if(event.feature.getProperty('id') == undefined)
                        {
                          
                          event.feature.setProperty('id', "0-" + time.getTime().toString(16).toUpperCase() + "-" + props.location.state.__ID + "-"  + Math.round((Math.pow(10,16) * Math.abs(event.feature.getGeometry().get().lng()))).toString(16).toUpperCase() + "-" + Math.round((Math.pow(10,16) * Math.abs(event.feature.getGeometry().get().lat()))).toString(16).toUpperCase());
                          const availableLabels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789abcdefghijklmnopqrstuvwxyz";
                          for(var i = 0; i < availableLabels.length; i++)
                          {
                            var exists = false;
                            mapRef.current.data.forEach((feature) => {
                              if(feature.getGeometry().getType() == "Point")
                              {
                                if(feature.getProperty('label') == availableLabels[i])
                                {
                                  exists = true;
                                }
                              }
                            });
                            if(!exists)
                            {
                              event.feature.setProperty('label', availableLabels[i]);
                              break;
                            }
                            if(i == availableLabels.length - 1)
                            {
                              event.feature.setProperty('label', "*");
                            }
                          }

                        }
                        
                      }
                      else
                      {
                        if(event.feature.getProperty('id') == undefined)
                        {
                          event.feature.setProperty('id', "1-" + time.getTime().toString(16).toUpperCase() + "-" + props.location.state.__ID + "-" + Math.round((Math.pow(10,16) * Math.abs(event.feature.getGeometry().getAt(0).getAt(0).lng()))).toString(16).toUpperCase() + "-" + Math.round((Math.pow(10,16) * Math.abs(event.feature.getGeometry().getAt(0).getAt(0).lat()))).toString(16).toUpperCase());
                          event.feature.setProperty('color', "#ff0000");
                        }
                      }
                      if(event.feature.getProperty('id') == undefined)
                      {
                        setCurrentFeature(event.feature);
                        setChanges(true);
                      }

                    });
                    mapRef.current.data.addListener('click', event => mapClick(event));
                    mapRef.current.data.addListener('setgeometry', event => {
                      setChanges(true);
                      setNewCenter(null);
                      if (event.feature.getGeometry().getType() === "Polygon") {
                        event.feature.setProperty('area', window.google.maps.geometry.spherical.computeArea(event.feature.g.g[0].g) * 10.7639);
                        setRoofArea(window.google.maps.geometry.spherical.computeArea(event.feature.g.g[0].g) * 10.7639);
                        let roofCoords = [];
                        event.feature.getGeometry().forEachLatLng((coord, i) => {
                          roofCoords.push(coord);
                        });
                        let perimeter = 0;
                        roofCoords.forEach((coord, i) => {
                          if(i > 0)
                          {
                            perimeter += window.google.maps.geometry.spherical.computeDistanceBetween(coord, roofCoords[i - 1]) * 3.28084;
                          }
                          else
                          {
                            perimeter += window.google.maps.geometry.spherical.computeDistanceBetween(coord, roofCoords[roofCoords.length - 1]) * 3.28084;
                          }
                        });
                        setRoofPerimeter(perimeter);
                        event.feature.setProperty('perimeter', perimeter);
                      }
                      else
                      {
                        setRoofArea(0);
                        setRoofPerimeter(0);
                      }
                    });
                    mapRef.current.data.addListener('setproperty', event => {
                      setChanges(true);
                    });
                    mapRef.current.data.addListener('mouseup', function(event){
                      setNewCenter(null);
                      // New point
                      if (event.feature.getGeometry().getType() === "Polygon") {
                        event.feature.setProperty('area', window.google.maps.geometry.spherical.computeArea(event.feature.g.g[0].g) * 10.7639);
                        setRoofArea(window.google.maps.geometry.spherical.computeArea(event.feature.g.g[0].g) * 10.7639);
                        let roofCoords = [];
                        event.feature.getGeometry().forEachLatLng((coord, i) => {
                          roofCoords.push(coord);
                        });
                        let perimeter = 0;
                        roofCoords.forEach((coord, i) => {
                          if(i > 0)
                          {
                            perimeter += window.google.maps.geometry.spherical.computeDistanceBetween(coord, roofCoords[i - 1]) * 3.28084;
                          }
                          else
                          {
                            perimeter += window.google.maps.geometry.spherical.computeDistanceBetween(coord, roofCoords[roofCoords.length - 1]) * 3.28084;
                          }
                        });
                        setRoofPerimeter(perimeter);
                        event.feature.setProperty('perimeter', perimeter);
                      }
                      else
                      {
                        setRoofArea(0);
                        setRoofPerimeter(0);

                      }
                    });
                    mapRef.current.data.addListener('removefeature', function(event){
                      var markerId = event.feature.getProperty('id');
                      setNewCenter(null);
                      setChanges(true);
                    });
                    

                  }}
                  mapContainerStyle={{
                      height: "100%",
                      width: "100%"
                  }}
                  zoom={17}
                  center={center}
                  tilt={0}
                  options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    mapTypeId: "hybrid",
                    rotateControl: false,
                    gestureHandling: 'greedy',
                    zoomControl: window.innerHeight > 600 ? true : false,
                  }}
              >
                {image.url != null &&
                
                <Marker
                  onLoad={marker => {
                    trackLocation({
                      onSuccess: ({ coords: { latitude: lat, longitude: lng } }) => {
                        marker.setPosition({ lat, lng });
                      },
                      onError: err => {

                      }
                        
                    });
                  }}
                  icon={image}
                  zIndex={1000}
                />
                }
              </GoogleMap>

          </div>

          {loading && <div style={{width:'100%', display: 'flex', justifyContent: 'center', textAlign: 'center'}}><CircularProgress /></div>}
      {/* </LoadScript> */}
      </div>
      {!loading &&
      <>
      {/*<div style={{width:'100%', height: '35vh'}}></div>*/}
        
      
      <TabPanel value={tab} index={2}>
      <MarkerDetails setChanges={setChanges}
        setImage={(image, newImage) => {
          images.filter(i => i === image).forEach(i => {
            i = newImage;
            setChanges(true);
          })
        }}
        loading={imagesLoading}
        currentFeature={currentFeature}
        __ID={props.location.state.__ID}
        images={images} mapRef={mapRef}
        changeDescription={(text) => { currentFeature.setProperty('label', text) }}
        changeCurrentFeature={(val) => setCurrentFeature(val)}
        setNewPicOpen={setNewPicOpen}
        label={currentLabel}
      />

    </TabPanel>
    <TabPanel value={tab} index={1}>
    {tab == 1 &&
    <>
    {imageGallery}
    </>
    }
    </TabPanel>
      {/*detailsTab == "jobImages" && !imagesLoading &&
      <JobImages images={images} currentFeature={currentFeature} filterType={detailsTab} loading={imagesLoading} __ID={props.location.state.__ID} mapRef={mapRef} changeDescription={(text) => { currentFeature.setProperty('label', text) }} changeCurrentFeature={(val) => setCurrentFeature(val)}/>

      {detailsTab == "gallery" && !imagesLoading &&
      <JobGallery newBounds={newBounds} changeNewBounds={(val) => setNewBounds(val)} changeCenter={(val) => setCenter(val)} images={images} currentFeature={currentFeature} findFeature={findFeature} filterType={detailsTab} loading={imagesLoading} __ID={props.location.state.__ID} mapRef={mapRef} changeDescription={(text) => { currentFeature.setProperty('label', text) }} changeCurrentFeature={(val) => setCurrentFeature(val)}/>
      }
                    */}   
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={newPicOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={newPicOpen}>
            <Paper className={classes.modalPaper}>
            <Button variant="contained" color="secondary" className={classes.button} onClick={() => {handleNewPictureClick();}}>
              <PhotoCameraIcon className={classes.leftIcon} />
              Take Picture
            </Button>
            {/*}
            <Button variant="contained" color="secondary" className={classes.button}  onClick={() => { setDetailsTab("jobImages"); setNewPicOpen(false) } }>
              <PhotoLibraryIcon className={classes.leftIcon} />
              Select from Job
        </Button>*/}
            <Button variant="contained" color="secondary" onClick={handleNewUploadPictureClick} className={classes.button}>
              <FileCopyIcon className={classes.leftIcon} />
              Upload
            </Button>
            </Paper>
          </Fade>
        </Modal>
        </>
}
  </>
  
  );
}