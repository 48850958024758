import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TextField, MenuItem } from '@material-ui/core';
import { useAuth0 } from "../../react-auth0-wrapper";
import appPaths from '../../applicationPaths.json';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
    modal: {
        
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formControl: {
      minWidth: 120,
      margin: theme.spacing(2),
      paddingBottom: theme.spacing(5)
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    appBar: {
        position: 'relative',
        minWidth: '300px',
    },
        title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
  }));

export default function NewTechModal(props)
{
    const classes = useStyles();
    const { isAuthenticated, loginWithRedirect, logout, getTokenSilently, user } = useAuth0();
    const [techList, setTechList] = useState([]);
    const [contentLoading, setContentLoading] = useState(true);
    const [selectedTech, setSelectedTech] = useState(props.currentlySelected);

    useEffect(() => {
        const callApi = async () => {
            try {
              const token = await getTokenSilently();
              const response = await fetch(appPaths.apiPath + "/api/getAllTechs", {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',  // sent request
                  'Accept':       'application/json'   // expected data sent back
                },
              });
              const responseData = await response.json();
              if(responseData.message)
              {
                /*responseData.data.forEach(tech => {
                    fetch(appPaths.apiPath + "/api/getUserByFmId", {
                        method: 'POST',
                        headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',  // sent request
                        'Accept':       'application/json'   // expected data sent back
                        },
                        body: JSON.stringify({id: tech.fieldData.__ID})
                    })
                    .then(res => { return res.json() })
                    .then(res => { tech.auth0 = res.data; setTechList(prevState => [...prevState.filter(tech1 => tech1.recordId !== tech.recordId), tech]); })
                })*/
                setTechList(responseData.data);
                setSelectedTech(selectedTech.map(tech => responseData.data.filter(response => response.fieldData.__ID == tech.fieldData.__ID)[0]))
                setContentLoading(false);
              }
              else
              {
                //alert(responseData);
              }
    
            } catch (error) {
              console.error(error);
            }
          };
            callApi();
    }, [getTokenSilently, props.__ID]);

  const handleClose = () => {
    props.addTech(selectedTech);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event) => {
      setSelectedTech(event.target.value);
  }

  const handleDelete = (event, tech) => {
      setSelectedTech(prevState => prevState.filter(i => i.fieldData.__ID !== tech.fieldData.__ID));
  }

    return (
    <Dialog
        fullScreen={fullScreen}
        //className={classes.modal}
        open={props.open}
        onClose={handleClose}

        //BackdropComponent={Backdrop}
    >
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                Selected Technicians
                </Typography>
                <Button color="inherit" onClick={handleClose}>
                save
                </Button>
            </Toolbar>
        </AppBar>
        {!contentLoading &&
        <FormControl className={classes.formControl}>
        <Select
            style={{minHeight: '49px', maxWidth:'100%'}}
            value={selectedTech}
            multiple={true}
            onChange={handleChange}
            displayEmpty={true}
            renderValue={value => <div style={{
                display: 'flex',
                flexWrap: 'wrap',
              }}> {value.map(tech => 
                <Chip
                    style={{margin: '2px'}}
                    key={tech.fieldData.__ID}
                    avatar={<Avatar src={"Auth0" in tech ? tech.Auth0.picture : ""} />}
                    label={tech.fieldData.Name_combined_ae}
                    clickable
                    className={classes.chip}
                    color="secondary"
                    onDelete={(event) => handleDelete(event, tech)}
                />
            
            )}
            </div>}
        >
            {techList.map(tech => 
                <MenuItem key={tech.fieldData.__ID} value={tech}>
                    <ListItemAvatar>
                        <Avatar src={"Auth0" in tech ? tech.Auth0.picture : ""} />
                    </ListItemAvatar>
                    <ListItemText primary={tech.fieldData.Name_combined_ae}>

                    </ListItemText>
                </MenuItem>
            )}
        </Select>
        </FormControl>
        }
    </Dialog>
    );
}